import {customApp} from "./customApp.json";

const APIParameters = () => {
    const baseUrl = customApp.baseUrl ||"https://field-test.voolks.com/";
    const EndPoints = {
        "login": 'api/members/login?include=user',
        "logout": 'api/members/logout',
        "killme": 'api/members/killme',
        "subscriptions": 'api/forms/mysubscriptions',
        "ptw": 'api/forms/myptw',
        "wf": 'api/forms/myWorkFlows',
        "tasks": 'api/members/{id}/task?filter={filter}',
        "uploadURL": 'api/containers/{tenantId}/upload/',
        "downloadFile": 'api/containers/{tenantId}/download/{filename}',
        "deleteFile": 'api/containers/{tenantId}/files/{filename}',
        "updMember": "api/members/{memberId}/",
        "uploadItem": "api/forms/{formId}/items/",
        "itemPDF": "api/departments/{deptId}/forms/{formId}/pdf/{itemId}",
        "integration": "api/integrations/{integrationId}/request",
        "integrations": 'api/integrations/?filter=%7B%22where%22%3A%7B%22id%22%3A%7B%22inq%22%3A{lstIntegrationId}%7D%7D%7D',
        "installations": "api/installations/?filter={filter}",
        "installation": "api/installations/{id}",
        "resetPassword":"request-password-reset",
        "register":"register"        
   
    }

    const reqBaseHeaders = {
        "accept": "application/json, text/plain, */*",
        "content-type": "application/json;charset=UTF-8",
        "accept-language": "es-AR,en-US;q=0.7,en;q=0.3"
    };

    const getFullUrl = (endPoint: string) => `${baseUrl}${endPoint}`;
    const loginEndpoint = () => getFullUrl(EndPoints.login);
    const logoutEndpoint = () => getFullUrl(EndPoints.logout);
    const killMeEndpoint = () => getFullUrl(EndPoints.killme);
    const subscriptionsEndpoint = () => getFullUrl(EndPoints.subscriptions);
    const ptwEndpoint = () => getFullUrl(EndPoints.ptw);
    const wfEndpoint = () => getFullUrl(EndPoints.wf);
    const integrationsEndpoint = (lstIntegrationId:string[]) => getFullUrl(EndPoints.integrations).replace("{lstIntegrationId}", encodeURIComponent(JSON.stringify(lstIntegrationId)));
    const tasksEndpoint = (memberId: string, filter: object) => getFullUrl(EndPoints.tasks).replace("{id}", memberId).replace("{filter}", encodeURIComponent(JSON.stringify(filter)));
    const downloadFile = (tenantId: string, filename: string) => getFullUrl(EndPoints.downloadFile).replace("{tenantId}", tenantId).replace("{filename}", encodeURIComponent(filename));
    const deleteFile = (uri:string) => uri.replace('/download/','/files/');
    const uploadURL = (tenantId: string) => getFullUrl(EndPoints.uploadURL).replace("{tenantId}", tenantId);
    const updMember = (memberId: string) => getFullUrl(EndPoints.updMember).replace("{memberId}", memberId);
    const uploadItem = (formId: string) => getFullUrl(EndPoints.uploadItem).replace("{formId}", formId);
    const itemPDF = (deptId: string, formId: string, itemId: string) => getFullUrl(EndPoints.itemPDF).replace("{deptId}", deptId).replace("{formId}", formId).replace("{itemId}", itemId);
    const integration = (integrationId: string) => getFullUrl(EndPoints.integration).replace("{integrationId}", integrationId);
    const installations = (filter: object) => getFullUrl(EndPoints.installations).replace("{filter}", encodeURIComponent(JSON.stringify(filter)));
    const installation = (id: string = "") => getFullUrl(EndPoints.installation).replace("{id}", id);
    const resetPasswordURL = ()=>getFullUrl(EndPoints.resetPassword);
    const registerURL = ()=>getFullUrl(EndPoints.register);

    return {
        baseUrl,
        reqBaseHeaders,
        loginEndpoint,
        logoutEndpoint,
        killMeEndpoint,
        downloadFile,deleteFile,
        subscriptionsEndpoint,ptwEndpoint,wfEndpoint,
        tasksEndpoint,
        uploadURL,
        updMember,
        uploadItem,
        itemPDF,
        integration,
        installations,
        installation,
        resetPasswordURL,
        registerURL,
        integrationsEndpoint
    }
}

export default APIParameters;