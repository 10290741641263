import { Platform } from "react-native";
import UAParser from "ua-parser-js";
import { getModel, getSystemVersion, getDeviceId,getUniqueId } from 'react-native-device-info';
const myDevice = () => {
    let deviceId: string = "webApp";
    let uniqueId:Function = ()=>new Promise((res,rej)=>res(deviceId));
    let deviceModel: string = "webApp";
    let systemVersion: string = "";
    if (Platform.OS == "web") {
        //var parser =  UAParser.UAParser().
        deviceId = "Chrome"; // parser.os.name.concat(".", parser.browser.name);        
        deviceModel = "web"; //parser.browser.version;
    } else {
        uniqueId = getUniqueId;
        deviceId = getDeviceId();
        deviceModel = getModel();
        systemVersion = getSystemVersion();
        console.log("deviceId",deviceId);

    }
    return { deviceId, deviceModel, systemVersion,uniqueId }
}
export default myDevice();