import React from "react"
import { Icon } from "native-base"
import { Ionicons, MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { Linking } from "react-native";
import { Platform } from 'react-native';
import * as Location from 'expo-location';
import Geolocation from '@react-native-community/geolocation';
import { urlFileToBase64 } from "./general";


export const getDisplayAndValue = (label: string) => {
  label = (label ?? "").toString()
  if (!label.includes('[object Object]')) {
    let idx: number = label.indexOf("|");
    if (idx > 0) {
      let display = label.substring(idx + 1);
      let value = label.substring(0, idx).replace("*", "");
      value = value == "" ? display : value;
      return { value, display, default: label.substring(0, idx).includes("*") }

    } else {
      return { display: label, value: label, default: false }
    }
  }
  return { display: "", value: null, default: false }
}

export const getGPSCoords = async (forceHighPrecision = 1000, statusStore = null): Promise<any> => {
  let { status } = await Location.getForegroundPermissionsAsync();
  if (status != "granted") {
    status = (await Location.requestForegroundPermissionsAsync()).status;
    if (status != "granted") throw new Error("Sin permiso al GPS");
  }
  if (Platform.OS == "web")
    return await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Lowest });
  else {

    return await (new Promise((resolve, reject) => {
      const tryGetLocation = async () => {
        try {
          Geolocation.getCurrentPosition(location => {
            console.log("geolocation2", location);
            if (statusStore)
              statusStore.setFlashMessage(`Buscando satelites...precision ${location?.coords?.accuracy.toFixed(2)} metros,${forceHighPrecision < 1000 ? "mínima requerida metros :".concat(forceHighPrecision.toString()) : ""}`, 10000)
            if (location?.coords?.accuracy > forceHighPrecision)
              setTimeout(tryGetLocation, 1000);
            else
              resolve(location);
          }, err => {
            console.log("Error obteniendo coordenadas del GPS:", err);
            statusStore.setFlashMessage(`Error obteniendo coordenadas del GPS: ${err.message}`);
            reject(err.message);
          }
            ,
            {
              timeout: 60000,
              maximumAge: forceHighPrecision < 1000 ? 4000 : undefined,
              enableHighAccuracy: forceHighPrecision < 1000 ? true: undefined
            });
        } catch (err: Error) {
          if (statusStore)
            statusStore.setFlashMessage(`Error obteniendo coordenadas del GPS: ${err.message}`);
        }
      }

      tryGetLocation();

    }));
  }

}

export const getItemTitle = (form: any, item: any) => {
  let useAsTitleField: string | undefined = "";
  if (form.pages == undefined && Array.isArray(form)) {
    form = { pages: [{ fields: form }] } //Para cuando viene directamente la coleccion de campos.
  }
  try {
    console.log("getItemTitle")
    useAsTitleField = form.pages.reduce((title: any, page: any) => {
      title = title.concat("").concat(page.fields.reduce((title, field) => {
        /*  if (field.type == "autonumeric") 
         return title; */
        let itemPayload = item[field.id] || item.payload.get(field.id);
        if (field.type == "group") {
          let titleRet = field.nodes.reduce((title2, field) => {
            if (field.templateData && field.templateData.useAsTitle && itemPayload.value) {
              if (itemPayload.type == "date") {
                return title2.concat(" ").concat(new Date(itemPayload.value).getYYYYMMdd());
              } else if (itemPayload.type == "timestamp") {
                return title2.concat(" ").concat(new Date(itemPayload.value).getYYYYMMddHHmmss());
              } else if (itemPayload.type == "docs") {
                return title2;
              } else {
                return title2.concat(" ").concat(getDisplayAndValue(String(itemPayload.value)).display);
              }
            }
            return title2;
          }, "");
          if (titleRet != "")
            return title.concat(" ").concat(titleRet.trim());
        }
        if (field.templateData && field.templateData.useAsTitle && itemPayload && itemPayload.value) {
          if (itemPayload.type == "date") {
            return title.concat(" ").concat(new Date(itemPayload.value).getYYYYMMdd());
          } else if (itemPayload.type == "timestamp") {
            return title.concat(" ").concat(new Date(itemPayload.value).getYYYYMMddHHmmss());
          } else if (itemPayload.type == "docs") {
            return title;
          } else {
            return title.concat(" ").concat(getDisplayAndValue(String(itemPayload.value)).display);
          }
        }
        return title;
      }, ""));

      return title.trim();
    }, "");
  } catch (e) {
    //console.log(e);
    useAsTitleField = "";
  }
  try {
    return useAsTitleField != "" ?
      useAsTitleField?.trim()
      :
      item._startedAt ?
        (new Date(item._startedAt).getYYYYMMddHHmmss()) //HORRIBLE OTROS FORMATOS 
        : (getDisplayAndValue(item[Object.keys(item)[0]].value).display || " ");
  } catch (e) {
    console.log(e);
    return "";
  }
}

export const getIconItem = (status: ("draft" | "closed" | "sent"), color: string = undefined, size: string = undefined) => {
  switch (status) {
    case 'draft':
      return (<Icon name="lock-open" size={size || "lg"} as={Ionicons} color={color || "opened.400"} />)
    case 'closed':
      return (<Icon name="lock-closed" size={size || "lg"} as={Ionicons} color={color || "closed.400"} />)
    case 'sent':
      return (<Icon name="cloud-done" size={size || "lg"} as={Ionicons} color={color || "done.400"} />)
  }
  return <></>;
}

export const getIconItemPTW = (status: string, color: string = undefined, size: string = undefined) => {
  switch (status) {
    case 'pending':
      return (<Icon name="pending" size={size || "lg"} as={MaterialIcons} color={color || "done.400"} />)
    case 'inprogress':
      return (<Icon name="progress-check" size={size || "lg"} as={MaterialCommunityIcons} color={color || "closed.400"} />)
    case 'waiting':
      return (<Icon name="md-warning-outline" size={size || "lg"} as={Ionicons} color={color || "warning.400"} />)
    case 'finished':
      return (<Icon name="checkbox-marked-circle" size={size || "lg"} as={MaterialCommunityIcons} color={color || "success.400"} />)
    case 'reject':
      return (<Icon name="cancel" size={size || "lg"} as={MaterialIcons} color={color || "black"} />)
    case 'error':
      return (<Icon name="robot-dead-outline" size={size || "lg"} as={MaterialCommunityIcons} color={color || "red.600"} />)
    case 'closed':
      return (<Icon name="marker-check" size={size || "lg"} as={MaterialCommunityIcons} color={color || "black"} />)
  }
  return <></>;
}

export const openPDF = async (itemsStore, item: any, toMail: boolean = false) => {
  let url = await itemsStore.getItemURLPDF(item.deptId, item.formId, item.id);
  if (url)
    Linking.openURL(!toMail ? url : encodeURI(`mailto:?&subject=Te han enviado un PDF - Field&body=Para ver el PDF sigue este link ${((url))}`));
}

export const isElementArchived = (fields, fieldId, isArchived) => {
  return fields.reduce((isArchived, field) => {
    if (isArchived) return isArchived;
    if (field.id == fieldId && field.isArchived) return true;
    if (field.nodes) return isElementArchived(field.nodes, fieldId, isArchived)
    return isArchived
  }, isArchived)
}

export const createDynamicParms = async (element: any, getPayloadElement: Function, parentId: string | undefined = undefined, recordIdx: number | undefined = undefined) => {
  try {
    let varsValue: any = {}
    for (const varname in element.integration.dynamicParams) {
      try {
        let payloadOfVar = getPayloadElement(element.integration.dynamicParams[varname], parentId, recordIdx);
        let value = payloadOfVar.value
        if (false && value && ["select", "multiple"].includes(payloadOfVar.type)) { //anulo esto porque antes no lo limpiaba a los caracteres extra
          value = getDisplayAndValue(value).value;
        } else if (value && payloadOfVar.type == "geo") {
          value = "".concat(value.coords.latitude, ",", value.coords.longitude);
        } else if (value && payloadOfVar.type == "simplelist") {
          value = "".concat(JSON.stringify(value));
        } else if (payloadOfVar.type == "docs" && value.length > 0) {
          value = await urlFileToBase64(value[0].uri)
        } else {
          value = value != undefined ? value.toString() : "";
        }
        if (value && value != "")
          varsValue[varname] = value;
      }
      catch (e) { }
    };

    for (const varname in element.integration.defaultParams) {
      varsValue[varname] = varsValue[varname] ?? element.integration.defaultParams[varname]
    }

    return varsValue;
  } catch (e) {
    return {}
  }
}

export const getDataFromIntegration = async (element: any,
  getPayloadElement: Function,
  parentId: string | undefined = undefined,
  recordIdx: number | undefined = undefined,
  getIntegration: Function) => {
  let dynamicParams = await createDynamicParms(element, getPayloadElement, parentId, recordIdx);
  let res = {};
  try {
    res = await getIntegration(element.integration.id, dynamicParams);
  } catch (e) {
    console.log(e);
  }

  return res;
}