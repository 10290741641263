import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ScrollView, ViewStyle, Pressable } from "react-native"
import { Badge, BadgeText, Text, StatusBar, Icon, Box, Button, ButtonIcon, HStack, VStack } from "@gluestack-ui/themed"
import { MaterialIcons, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";

import { DrawerScreenProps } from "@react-navigation/drawer"
import { useStores } from "../../models"
import { HomeStackParamList, useBackButtonHandler } from "../../navigators"
import Spacer from "../../components/base/Spacer"


export const FormsListScreen: FC<DrawerScreenProps<HomeStackParamList, "FormsList">> = observer(function FormsListScreen({ navigation, route }) {
  const { authenticationStore, formsStore, statusStore, itemsStore } = useStores();
  const user = authenticationStore.userInfo.user;
  const department = formsStore.mySubscriptions().subscriptions.find((item: any) => route.params?.deptId ? item.id == route.params.deptId : true);
  useBackButtonHandler(() => navigation.openDrawer())
  if (department == undefined) {
    navigation.openDrawer()
    return <Text>No Dept</Text>;
  }
  let items = itemsStore.myItems({ deptId: department.id });
  let my_items_closed = items?.filter((item: any) => { if (item.deptId == department.id && item.status == "closed") return item });
  let my_items_draft = items?.filter((item: any) => { if (item.deptId == department.id && item.status == "draft") return item });

  let leftButton = <Button width={50} height={50} p="$1" variant="solid" key="itemsStore-upload" onPress={navigation.openDrawer}>
    <ButtonIcon as={MaterialIcons} name="menu" fontWeight="$extrabold" size="xl" color="#FFF" />
  </Button>

  return (<>

    <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
    <Box bg="$primary400" />
    <HStack bg="$primary400" px={0} py={0} justifyContent="space-between" alignItems="center" w="100%">
      <HStack alignItems="center" maxWidth={statusStore.windowDimensions.width - 140} >
        {leftButton}
        <Text color="white" fontSize={18} >
          {department.name}
        </Text>
      </HStack>
      <HStack>
        {my_items_closed.length > 0 ?
          <VStack marginRight={2}>
            <Badge h={21} w={22} bg="$red600" borderRadius="$full" mb={-22} mr={0} zIndex={1}
              variant="solid" alignSelf="flex-end" ><BadgeText size="2xs" color="$white">{my_items_closed.length}</BadgeText>
            </Badge>
            <Button width={50} height={50} p="$1" variant="solid" key="itemsStore-upload" onPress={() => itemsStore.upload({ deptId: department.id })}>
              <ButtonIcon as={MaterialCommunityIcons} name="cloud-upload" fontWeight="$extrabold" size="xl" color="#FFF" />
            </Button>
          </VStack>
          : null
        }
        <Button width={50} height={50} p="$1" variant="solid" key="itemsStore-upload"
          onPress={() => formsStore.getSubscriptions(true, true)}>
          <ButtonIcon as={Ionicons} name="refresh-outline" fontWeight="$extrabold" size="xl" color="#FFF" />
        </Button>

      </HStack>
    </HStack>
    <VStack bg="$white" flex={1}  >
      <HStack bgColor="$light200" px={"$5"} py={"$2"} my={0} justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"$light300"} >
        <Text fontSize={14} color="$secondary400">Formularios Disponibles</Text>
      </HStack><ScrollView >
        {[].concat(department.forms).sort((a, b) => { return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1 }).map((form: any, idx: number) => {
          let items_closed = my_items_closed?.filter((item: any) => { if (item.formId == form.id) return item }).length
          let items_draft = my_items_draft?.filter((item: any) => { if (item.formId == form.id) return item }).length
          return (form.type != undefined ?
            null
            :
            (
              <Box key={`box${form.id}.${idx}`} px={0} >
                <Pressable onPress={() => navigation.navigate("ItemsList", { deptId: department.id, formId: form.id, forReview: false })} >
                  <HStack minHeight={40} paddingVertical="$2" space={"md"} px={2} justifyContent="center" alignItems={"center"} borderBottomWidth={1} borderBottomColor={"$light200"}>
                    <Text width={"70%"} fontSize={"$md"} style={{ flexWrap: "wrap", justifyContent: "center", }} >
                      {form.name}
                    </Text>
                    <Spacer />
                    {items_draft > 0 ? <Badge bg="$opened400" borderRadius="$full" zIndex={1}
                      variant="solid" alignSelf="flex-end" ><BadgeText size="xs" color="$white">{items_draft}</BadgeText>
                    </Badge> : <></>}
                    {items_closed > 0 ? <Badge bg="$closed400" borderRadius="$full" zIndex={1}
                      variant="solid" alignSelf="flex-end" ><BadgeText size="xs" color="$white">{items_closed}</BadgeText>
                    </Badge> : <></>}
                    <Icon name="arrow-forward" as={Ionicons} size="md" color="$light400" />
                  </HStack>
                </Pressable>
              </Box>))
        })}
      </ScrollView>
    </VStack>

  </>
  )
})

const $root: ViewStyle = {
  flex: 1,
}
