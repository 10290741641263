import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import "react-native-gesture-handler"; //this should be the first import in your code

import { HomeNavigator, useNavigationPersistence, } from "../navigators"
import { NavigationContainer } from "@react-navigation/native";
import { navigationRef, } from "../navigators/navigationUtilities"

import * as storage from "../utils/storage"
import { Linking } from "react-native";

export const NAVIGATION_PERSISTENCE_KEY = "HOME_NAVIGATION_STATE"


const config = {
  screens: {
    ItemsList: 'dept/:deptId/form/:formId',
    Settings: 'user', 
    WFHome:'workflows',
    TasksList: 'tasks',
  },
};

const linking = {
  prefixes: ['https://webapp.voolks.com', 'voolksfield://', 'voolksField://','field4://'],
  config
};

// @ts-ignore
export const HomeScreen: FC = observer(function HomeScreen(_props) {
  const {
    initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)
  if (!isNavigationStateRestored ) return null    
  const initialNavigationState2 = initialNavigationState;
  return <NavigationContainer linking={linking} ref={navigationRef} initialState={initialNavigationState2}
   onStateChange={onNavigationStateChange} ><HomeNavigator/></NavigationContainer>;
  


});
