import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { AppState, Linking, Modal, ViewStyle, } from "react-native"
import { useStores } from "../models"
import { HStack, Icon, View, ScrollView, Spinner, Text, VStack, IconButton, Spacer, AlertDialog, Button, Pressable } from "native-base"
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { navigate } from "../navigators"
import * as Storage from "../utils/storage";
import { useFirebaseMessaging } from '../services/firebaseConfig'
import backgroundTasks from "../utils/backgroundTasks"

export type ActionTYPE = {
  name: string;
  callback: any;
}

export type AlertDialogTYPE = {
  title: string;
  message: string;
  cancelAction: ActionTYPE;
  actions: ActionTYPE[];
}




export const StatusScreen: FC = observer(function StatusScreen() {
  const { authenticationStore, statusStore, formsStore, tasksStore, itemsStore } = useStores()
  const { firebaseMessage, firebaseMessageClean } = useFirebaseMessaging(authenticationStore.updPushToken);
  const { width, height } = statusStore.windowDimensions;
  const [isLogVisible, setLogVisible] = React.useState(true);
  const [alertDialog, setAlertDialog] = React.useState<AlertDialogTYPE | undefined>();

  const cancelRef = React.useRef();

  React.useEffect(() => {
    console.log("firebaseMessage", firebaseMessage)
    if (firebaseMessage!=undefined)
      setAlertDialog({
        title: firebaseMessage.notification.title,
        message: firebaseMessage.notification.body,
        cancelAction: { name: "Cerrar", callback: () => { firebaseMessageClean() } },
        actions: firebaseMessage.data?.click_action == "app.task" ?
          [
            {
              name: "Sincronizar y Cerrar", callback: async () => { await formsStore.getSubscriptions(); await tasksStore.getTasks();firebaseMessageClean();  }
            },
            {
              name: "Sincronizar e ir a Tareas", callback: async () => { await formsStore.getSubscriptions(); await tasksStore.getTasks();firebaseMessageClean(); navigate("TasksList"); }
            },
          ]
          :
          firebaseMessage.data?.additionalData?.includes("isPTW")?
          [
            {
              name: "Sincronizar", callback: async () => { Storage.removeItem("must_sync"); await itemsStore.upload({});await formsStore.getSubscriptions(); firebaseMessageClean();  }
            }
          ]
          :[]
      });
    else
      setAlertDialog(undefined)
  }, [firebaseMessage]);

  React.useEffect(() => {
    console.log("statusStore.alertMessage", statusStore.alertMessage)
    if (statusStore.alertMessage && statusStore.alertMessage.title)
      setAlertDialog({
        title: statusStore.alertMessage.title,
        message: statusStore.alertMessage.body,
        cancelAction: { name: "Cerrar", callback: () => { setAlertDialog(undefined); statusStore.setAlertMessage(undefined)} },
        actions: []
      });
    else
      setAlertDialog(undefined)
  }, [statusStore.alertMessage]);

  const onBackground = async (nextState) => {
    console.log("App.onBackground 2", nextState)
    if(authenticationStore.userInfo?.user)
      await backgroundTasks(authenticationStore, itemsStore,tasksStore); 
  }

  React.useEffect(() => {
    const handleDeepLink = async () => {
      const url = await Linking.getInitialURL();
      console.log("STATUSScreen DEEP LINK2",url)
      if (url) {
        // Aquí puedes procesar el deep link y navegar a la pantalla correspondiente
        const parsedLink = url.match(/field4:\/dept\/(\w+)\/form\/(\w+)/);
        if (parsedLink) {
          const [_, deptId, formId] = parsedLink;
          // Navegar a la pantalla indicada por el deep link
         navigate('ItemsList', { deptId, formId });
          console.log("STATUSScreen DEEP LINK3", parsedLink)
        }
      }
    };
    console.log("STATUSScreen DEEP LINK")
    const listener = AppState.addEventListener("change", onBackground);
    handleDeepLink();
    return () => {
      listener.remove();
    }
  }, []);

  return <View style={[{ position: "absolute", top: 0, left: 0, margin: 0, zIndex: 99 }]}>
    <Modal transparent={true} visible={statusStore.status == "pending"} onRequestClose={() => { }} animationType="none">
      <View style={[$containerLoading, $horizontal]}>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Spinner size="lg" />

        </View>
        <VStack bgColor={"gray.100"} mr="1" p="0" shadow={2} rounded={"sm"} height={isLogVisible ? height * 0.66 : 10}>
          <Pressable onPress={() => setLogVisible(!isLogVisible)}>
          <HStack bgColor={"gray.600"} rounded={"sm"} alignItems="center" p="4">
            <Text bold mx="2" color={"white"}>{statusStore.loadingInfoLst.length > 0 ? statusStore.loadingInfoLst[0].title : "Procesando..."}</Text>
            <Spacer /><Icon as={MaterialCommunityIcons} color="amber.400" name="message-processing-outline" size="md" />
          </HStack>
          </Pressable>
          <ScrollView>
            {statusStore.loadingInfoLst.map((info, idx) =>
              <HStack key={"info".concat(idx)} p="2" width="full" alignItems={"center"}>
                <Text >{info.title}</Text>
                {info.spinner == true ?
                  <Spinner size="sm" />
                  : info.spinner == false ?
                    <Icon as={Ionicons} mr="3" color={"success.600"} name="checkmark" size="md" />
                    : <></>
                }
              </HStack>
            )}
          </ScrollView>
        </VStack>
      </View>
    </Modal>

    <View bgColor="danger.400" style={[{ display: statusStore.flashmessage != "" ? "flex" : "none", width: width }, $horizontal]}>
      <Text style={{ color: "#FFF", padding: 10 }}>{statusStore.flashmessage}</Text>
    </View>

    {alertDialog == undefined ? <></> :
      <AlertDialog leastDestructiveRef={cancelRef} isOpen={alertDialog != undefined} >
        <AlertDialog.Content>
          <AlertDialog.CloseButton onPress={alertDialog.cancelAction.callback} />
          <AlertDialog.Header>{alertDialog?.title}</AlertDialog.Header>
          <AlertDialog.Body>{alertDialog?.message}</AlertDialog.Body>
          <AlertDialog.Footer>

            {alertDialog.actions.map((action, idx) => {
              return <Button key={`btn${idx}`} m="1" onPress={action.callback}>
                {action.name}
              </Button>

            })}

          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    }

  </View>
})

const $containerLoading: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  backgroundColor: "rgba(255,255,255,0.75)",

}

const $horizontal: ViewStyle = {
  justifyContent: "space-around",
  padding: 10
}
