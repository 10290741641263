
import React from "react"
import { FormulaElement, MediaElement, CheckElement, DateTimeElement, DurationElement, ElementBase, GeoElement, MultipleElement, NumberElement, QrElement, TextElement, TimestampElement, TitleElement, GroupElement, SimpleListElement, PopulateElement, SignatureElement, ValidateElement } from "."
import { FlatList } from "native-base";

export const FieldsList = ({ scroll, itemsStore, fields, parentId = undefined, forReview = false, recordIdx = undefined }) => {
    console.log("FieldsList", "parentId", parentId)
    forReview = forReview == true;

    const putFields = (fields: any[], parentId: string = undefined, recordIdx = undefined) => {
        const payload = itemsStore.itemActive.payload;
        return fields?.map((field: any, idx: number) => {
            if (!forReview
                || field.type == 'checkbox' || field.type == 'group' || field.type == 'simplelist' || field.type == 'title'
                || (payload.get(field.id) && JSON.stringify(payload.get(field.id).value) != "" && payload.get(field.id).value != null)) {
                if (field.isArchived)
                    return (null);
                let fieldType = field.type == "sendemail" ? (field.templateData.asSelect ? "select" : "text") : field.type;
                switch (fieldType) {
                    case 'time':
                    case 'date':
                        return <DateTimeElement parentId={parentId} element={field} key={"DateTimeElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'duration':
                        return <DurationElement parentId={parentId} element={field} key={"DateTimeElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'checkbox':
                        return <CheckElement parentId={parentId} element={field} key={"CheckElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'select':
                    case 'multiple':
                        return <MultipleElement parentId={parentId} element={field} key={"MultipleElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'timestamp':
                        return <TimestampElement parentId={parentId} element={field} key={"TimestampElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'geo':
                        return <GeoElement parentId={parentId} element={field} key={"GeoElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'text':
                        return <TextElement parentId={parentId} element={field} key={"TextElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'qr':
                        return <QrElement parentId={parentId} element={field} key={"TextElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'docs':
                    case 'video':
                    case 'photo':
                        return <MediaElement parentId={parentId} element={field} key={"MediaElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'number':
                        return <NumberElement parentId={parentId} element={field} key={"NumberElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'formula':
                        return <FormulaElement parentId={parentId} element={field} key={"FormulaElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'group':
                        return field.nodes?.length > 0 ? (<GroupElement parentId={parentId} element={field} key={"GroupElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} >
                            {
                                putFields(field.nodes, parentId, recordIdx)
                            }
                        </GroupElement>) : null;
                    case 'simplelist':
                        return field.nodes?.length > 0 ? (<SimpleListElement parentId={parentId} element={field} key={"SimpleListElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx}>
                            {
                                putFields(field.nodes, field.id, recordIdx)
                            }
                        </SimpleListElement>) : null;
                    case 'title':
                        return <TitleElement parentId={parentId} element={field} key={"TitleElement".concat(field.id)} recordIdx={recordIdx} />
                    case 'populate':
                        return <PopulateElement parentId={parentId} element={field} key={"PopulateElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'validate':
                        return <ValidateElement parentId={parentId} element={field} key={"ValidateElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    case 'sign':
                        return <SignatureElement parentId={parentId} element={field} key={"SignatureElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                    default:
                        return <ElementBase errors={[]} element={field} key={"ElementBase".concat(field.id)} recordIdx={recordIdx} />
                }
            }
            return null;

        });
    }

    const putField = (field: any, parentId: string = undefined, recordIdx = undefined) => {
        const payload = itemsStore.itemActive.payload;
        if (!forReview
            || field.type == 'checkbox' || field.type == 'group' || field.type == 'simplelist' || field.type == 'title'
            || (payload.get(field.id) && JSON.stringify(payload.get(field.id).value) != "" && payload.get(field.id).value != null)) {
            if (field.isArchived)
                return (null);
            let fieldType = field.type == "sendemail" ? (field.templateData.asSelect ? "select" : "text") : field.type;
            switch (fieldType) {
                case 'time':
                case 'date':
                    return <DateTimeElement parentId={parentId} element={field} key={"DateTimeElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'duration':
                    return <DurationElement parentId={parentId} element={field} key={"DateTimeElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'checkbox':
                    return <CheckElement parentId={parentId} element={field} key={"CheckElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'select':
                case 'multiple':
                    return <MultipleElement parentId={parentId} element={field} key={"MultipleElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'timestamp':
                    return <TimestampElement parentId={parentId} element={field} key={"TimestampElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'geo':
                    return <GeoElement parentId={parentId} element={field} key={"GeoElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'text':
                    return <TextElement parentId={parentId} element={field} key={"TextElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'qr':
                    return <QrElement parentId={parentId} element={field} key={"TextElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'docs':
                case 'video':
                case 'photo':
                    return <MediaElement parentId={parentId} element={field} key={"MediaElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'number':
                    return <NumberElement parentId={parentId} element={field} key={"NumberElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'formula':
                    return <FormulaElement parentId={parentId} element={field} key={"FormulaElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'group':
                    return field.nodes?.length > 0 ? (<GroupElement parentId={parentId} element={field} key={"GroupElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} >
                        {
                            putFields(field.nodes, parentId, recordIdx)
                        }
                    </GroupElement>) : null;
                case 'simplelist':
                    return field.nodes?.length > 0 ? (<SimpleListElement parentId={parentId} element={field} key={"SimpleListElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx}>
                        {
                            putFields(field.nodes, field.id, recordIdx)
                        }
                    </SimpleListElement>) : null;
                case 'title':
                    return <TitleElement parentId={parentId} element={field} key={"TitleElement".concat(field.id)} recordIdx={recordIdx} />
                case 'populate':
                    return <PopulateElement parentId={parentId} element={field} key={"PopulateElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'validate':
                    return <ValidateElement parentId={parentId} element={field} key={"ValidateElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                case 'sign':
                    return <SignatureElement parentId={parentId} element={field} key={"SignatureElement".concat(field.id)} forReview={forReview} recordIdx={recordIdx} />
                default:
                    return <ElementBase errors={[]} element={field} key={"ElementBase".concat(field.id)} recordIdx={recordIdx} />
            }
        }
        return null;
    }
    if(scroll)
        return <>{putFields(fields, parentId, recordIdx)}</>;
    return <><FlatList data={fields}
        renderItem={({ item }) => putField(item, parentId, recordIdx)}
        keyExtractor={item => item.id}
    ></FlatList>
    </>
}