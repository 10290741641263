import AsyncStorage from '@react-native-async-storage/async-storage' 
const keyNew = (key) => { return "st-".concat(key) }
const getItemPart = async (key) => {
    return await AsyncStorage.getItem(key);
}
const getItem = async (key) => {
    try {
        let keyOriginal = key;
        key = keyNew(key);
        let store = "";
        let numberOfParts:string|number = await AsyncStorage.getItem(key);
        if (typeof (numberOfParts) === 'undefined' || numberOfParts === null || isNaN(parseInt(numberOfParts))) {
            //migracion de data anterior si existe
            let originalValue = null;
            originalValue = await AsyncStorage.getItem(keyOriginal + "-part0");
            if (originalValue && originalValue!="" ) {
                //es version 165
                numberOfParts = await AsyncStorage.getItem(keyOriginal);
                numberOfParts = parseInt(numberOfParts);
                for (let i = 0; i < numberOfParts; i++) { store += await AsyncStorage.getItem(keyOriginal + "-part" + i); }
                await setItem(keyOriginal, JSON.parse(store))
                return await JSON.parse(store);
            } else {
                //es version 164 o anterior
                originalValue = await AsyncStorage.getItem(keyOriginal);
                if (originalValue || originalValue != null) {
                    await setItem(keyOriginal, originalValue)
                    return originalValue;
                } else
                    return null;
            }
        } else
            numberOfParts = parseInt(numberOfParts);
        for (let i = 0; i < numberOfParts; i++) { store += await AsyncStorage.getItem(key + "-part" + i); }
        if (store === "")
            return null;
        return JSON.parse(store);
    }
    catch (error) {
        console.log("Could not get [" + key + "] from store.");
        console.log(error);
        return null;
    }
};

const setItem = async (key, data) => {
    try {
        key = keyNew(key);
        await removeItem(key);
        const store = JSON.stringify(data).match(/.{1,1000000}/g);
        store.forEach((part, index) => { AsyncStorage.setItem((key + "-part" + index), part); });
        AsyncStorage.setItem(key, ("" + store.length));
    }
    catch (error) {
        console.log("Could not save store : ");
        console.log(error.message);
    }
};

const removeItem = async (key) => {
    try {
        key = keyNew(key);
        let numberOfParts = await AsyncStorage.getItem(key);
        if (typeof (numberOfParts) !== 'undefined' && numberOfParts !== null) {
            numberOfParts = parseInt(numberOfParts);
            for (let i = 0; i < numberOfParts; i++) { AsyncStorage.removeItem(key + "-part" + i); }
            AsyncStorage.setItem(key, ("0"));
        }
    }
    catch (error) {
        console.log("Could not clear store : ");
        console.log(error.message);
    }
};

const getAllKeys =  async  ()=>{
    return  await AsyncStorage.getAllKeys();
}

export default { getItem, getItemPart, setItem, removeItem,getAllKeys };