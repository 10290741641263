import App from "./app/app"
import React from "react"
/*  console.log = (function() {
  var console_log = console.log;
  var timeStart = new Date().getTime();
  
  return function() {
    var delta = new Date().getTime() - timeStart;
    var args = [];
    args.push((delta / 1000).toFixed(2) + ':');
    for(var i = 0; i < arguments.length; i++) {
      args.push(arguments[i]);
    }
    console_log.apply(console, args);
  };
})()  */


function useExternalScripts({ url }) {

  React.useEffect(() => {    
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", url);
    head!.appendChild(script);

    return () => {
      head!.removeChild(script);
    };
    
  }, [url]);
};
//console.log = function () {};
console.warn = function () {};
function IgniteApp() {  
  useExternalScripts({ url: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC5HxR2IAiiLhXIuCQxctsKq7AVp1CaGmI" });
  return <App hideSplashScreen={()=>{}}/>
}

export default IgniteApp
