import { Box, HStack, Icon, IconButton, Input, Spacer, Text, TextArea } from 'native-base';
import React, { FC } from 'react';
import { ElementBase, IElementBaseProps, initializeElement } from '..';
import { FontAwesome5 } from "@expo/vector-icons";
export const NumberElement: FC<IElementBaseProps> = (({ ...props }) => {
    const {
        itemsStore, element, statusStore,
        payload, setPayload, errorList, setErrorList
    } = initializeElement(props);
    
    const btnSizes = ['5xl', '3xl', 'xl', 'lg', 'md']
    let lstValuesCounter = [];
    if (element.templateData.btnCounter) {
        lstValuesCounter = (element.templateData.valuesCounter || "1").split(",")
    }
    const handleChange = (value: string | undefined) => {       
        value = (value ?? "").replace(",",".");    
        value =  (value && (!isNaN(Number(value)) || value == '-') ? value : "");
        let errorElement = itemsStore.getErrorElement(element, value);        
        let decimalIndexOf = value.toString().indexOf(".");
        if (value && decimalIndexOf > 0 && typeof element.templateData.maxDecimalsCount == "number") {
            let pos = element.templateData.maxDecimalsCount > 0 ? element.templateData.maxDecimalsCount + 1 : element.templateData.maxDecimalsCount;
            value = value.toString().substring(0, decimalIndexOf + pos);
        }
        setErrorList(errorElement.errorList)
        setPayload({ ...payload, value: value, valid: errorElement.valid });
    }
    
    const handleKBToolbar = (val: string) => {   
        if (val != ""){
            let payload=itemsStore.getPayloadElement(element.id, props.parentId);    
            val = (payload.value || "").concat(val);
        }        
        handleChange(val)
    }
    
    return <ElementBase errors={errorList} element={element} forReview={props.forReview} >
        {props.forReview ?
            <Text style={{ color: (element.templateData.textColor ? element.templateData.textColor : "#000") }}>
                {payload.value}
            </Text>
            :
            <HStack alignItems="center" justifyItems={"start"} >
                <Input variant="unstyled"
                    keyboardType="numeric"
                    onFocus={() => statusStore.configKBToolbar({ type: 'numeric', fn: handleKBToolbar })}
                    onChangeText={handleChange}
                    placeholder={element.templateData.placeholder || ""}
                    value={payload.value?.toString() || ""}
                    width={'full'} size="md"
                    InputLeftElement={<>
                        {lstValuesCounter.map((value: number, idx: number) =>
                            <IconButton key={'btnMinus'.concat(idx.toString())}
                                borderRadius={"full"}
                                m="1"
                                p="0"
                                colorScheme="danger"
                                icon={<Icon as={FontAwesome5} name="minus-circle" size={btnSizes[idx]} />}
                                onPress={() => {
                                    let newVal = parseFloat(payload.value ?? 0); newVal = isNaN(newVal) ? 0 : newVal; newVal -= value;
                                    if (element.templateData.minimum == undefined || element.templateData?.minimum <= newVal)
                                        handleChange(newVal.toString());
                                    else
                                        handleChange(element.templateData.minimum.toString());
                                }
                                }
                            />
                        ).reverse()}
                        <Icon as={FontAwesome5} name="hashtag" />
                    </>}
                    InputRightElement={<>
                        {lstValuesCounter.map((value: number, idx: number) =>
                            <IconButton key={'btnPlus'.concat(idx.toString())}
                                borderRadius={"full"}
                                m="1"
                                p="0"
                                colorScheme="success"
                                icon={<Icon as={FontAwesome5} name="plus-circle" size={btnSizes[idx]} />}
                                onPress={() => {
                                    let newVal: any = parseFloat(payload.value ?? 0);
                                    //@ts-ignore
                                    newVal = isNaN(newVal) ? 0 : newVal; newVal = parseFloat(newVal) + parseFloat(value);
                                    if (element.templateData.minimum == undefined || element.templateData.maximum == undefined || element.templateData.maximum >= newVal)
                                        handleChange(newVal.toString());
                                    else
                                        handleChange(element.templateData?.maximum.toString());

                                }
                                }
                            />
                        )}
                    </>}
                />
            </HStack>
        }
    </ElementBase>
});