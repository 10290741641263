/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import "./i18n"
import "./utils/ignoreWarnings"
import { useFonts } from "expo-font"
import React from "react"
import { initialWindowMetrics, SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context"
import { useInitialRootStore } from "./models"
import { AppNavigator } from "./navigators"
import { ErrorBoundary } from "./screens/ErrorScreen/ErrorBoundary"
import { customFontsToLoad } from "./theme"
import Config from "./config"
import { customApp } from "./config";
import { extendTheme, NativeBaseProvider } from "native-base"
import { StatusScreen } from "./screens"
import { extendDatePrototipe } from "./utils/formatDate"
import { LogBox, Platform } from 'react-native';
import { KBToolBar } from "./components/KBToolBar"
import { SplashScreen } from "./screens/SplashScreen"
import {config as configGlueStack} from "./configGlueStack/gluestack-ui.config"
import { Box,Text, GluestackUIProvider,KeyboardAvoidingView } from "@gluestack-ui/themed"

LogBox.ignoreLogs(['new NativeEventEmitter', '[Error: [mobx-state-tree] Warning: Attaching onAction']);
console.disableYellowBox = true;

interface AppProps {
  hideSplashScreen: () => Promise<void>
}

/**
 * This is the root component of our app.
 */
function App(props: AppProps) {
  const { hideSplashScreen } = props
  const [areFontsLoaded] = useFonts(customFontsToLoad)
  const [visibleCustomSplash, setVisibleCustomSplash] = React.useState(true);

  const { rehydrated } = useInitialRootStore(() => {
    hideSplashScreen();
    setTimeout(hideSplashScreen, 10);    
  })

  if (!rehydrated || !areFontsLoaded) return null

  const theme = extendTheme({
    colors: {
      "primary": customApp.styles.primaryPalette,      
      "secondary": customApp.styles.secondaryPalette,

      "closed": {
        "50": "#a7b1ed",
        "100": "#8a97e2",
        "200": "#707fd4",
        "300": "#5868c4",
        "400": "#3f51b5",
        "500": "#404e9d",
        "600": "#3f4a86",
        "700": "#3d4571",
        "800": "#393f5d",
        "900": "#34384b"
      }

      , "opened": { "400": "#62b1f6" }
      , "done": { "400": "#d9534f" },

      "info": {
        "50": "#f6fbff",
        "100": "#62b1f6",
        "200": "#a8d5fd",
        "300": "#86c3f8",
        "400": "#62b1f6",
        "500": "#4fa4ef",
        "600": "#4099e6",
        "700": "#338ddc",
        "800": "#2b81cc",
        "900": "#2e74b2"
      },

      "danger": {
        "50": "#facecd",
        "100": "#f4aeac",
        "200": "#eb908d",
        "300": "#e07470",
        "400": "#d9534f",
        "500": "#cd4843",
        "600": "#bd403c",
        "700": "#a4403d",
        "800": "#8d3f3c",
        "900": "#773d3b"
      }

    }

  });

  extendDatePrototipe();

  // otherwise, we're ready to render the app
  return (<KeyboardAvoidingView
    behavior={Platform.OS === "ios" ? "height" : "height"}
    style={{ flex: 1, zIndex: 999 }}
  ><GluestackUIProvider config={configGlueStack}><NativeBaseProvider theme={theme}>
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <ErrorBoundary catchErrors={Config.catchErrors}>
        {visibleCustomSplash ? <SplashScreen hideMe={()=>setVisibleCustomSplash(false)} /> : <SafeAreaView style={{flex:1,backgroundColor:"#000"}}><AppNavigator /></SafeAreaView>}
        <StatusScreen />
        <KBToolBar />
      </ErrorBoundary>
    </SafeAreaProvider>
  </NativeBaseProvider></GluestackUIProvider></KeyboardAvoidingView>
  )
}

export default App
