import React from "react";
import { Icon, Button, HStack, } from "native-base"
import { Keyboard, } from "react-native"

import { useStores } from "../models";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { onAction } from "mobx-state-tree";

export const KBToolBar = (props: any) => {
    const { statusStore } = useStores();
    const [isKeybVisible, setKeybVisible] = React.useState<boolean>(false)
    const [configKB, setConfigKB] = React.useState<{ type: String | undefined, fnCallback: Function | undefined }>({ type: undefined, fnCallback: undefined })
    React.useEffect(() => {
        const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
            setKeybVisible(true);
        });
        const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
            setConfigKB({ type: undefined, fnCallback: undefined })
            setKeybVisible(false);
        });

        let _disposerOnAction = onAction(statusStore, call => {
            if (call.name == "configKBToolbar") {
                console.log(call.args[0]);
                setConfigKB({ type: call.args[0].type, fnCallback: call.args[0].fn })
            }
        })
        return () => {
            _disposerOnAction();

            showSubscription.remove();
            hideSubscription.remove();
        }; 
    }, []);
    const keybTBHandleFunction = (x) => {
        configKB.fnCallback(x);
    }
    return isKeybVisible && configKB.type == "numeric" ?
        <HStack bg="light.300" alignItems="center" safeAreaBottom>
            <Button width="1/3" p="2" colorScheme="light" variant="subtle"
                onPress={() => { keybTBHandleFunction(".") }}
                leftIcon={<Icon as={MaterialCommunityIcons} name="decimal" size="lg" />}>
                Decimal
            </Button>
            <Button width="1/3" p="2" colorScheme="light" variant="subtle"
                onPress={() => { keybTBHandleFunction("-") }}
                leftIcon={<Icon as={MaterialCommunityIcons} name="numeric-negative-1" size="lg" />}>
                negativo
            </Button>
            <Button width="1/3" p="2" colorScheme="light" variant="subtle"
                onPress={() => { keybTBHandleFunction("") }}
                leftIcon={<Icon as={MaterialCommunityIcons} name="close" size="lg" />}>
                VACIAR
            </Button>
        </HStack>
        :
        <></>
}