import React from "react";
import { Icon, Button, View, Text, Box, HStack, Spacer, Badge, Image, Pressable, Menu, Divider, VStack, IconButton } from "native-base"
import { MaterialIcons, Ionicons, SimpleLineIcons, MaterialCommunityIcons, Octicons } from "@expo/vector-icons";
import { ImageBackground, ImageStyle, Platform, ViewStyle, useWindowDimensions } from "react-native"
import { DrawerContentScrollView } from "@react-navigation/drawer";
import * as FileSystem from 'expo-file-system';
import { useStores } from "../models";
import { customApp } from "../config";
import { onPatch } from "mobx-state-tree";
import * as Storage from "../utils/storage";


export const SideMenu = ((props: any) => {
    const { authenticationStore, formsStore, integrationsStore, tasksStore, itemsStore } = useStores();
    const dimensions = useWindowDimensions();
    const [expandForms, setExpandForms] = React.useState(dimensions.height > 800);
    const [rendered, setRender] = React.useState(<></>);
    const [mustSync, setMustSync] = React.useState<boolean>(false);

    const renderMe = () => {
        const isFieldForms = (customApp.user && customApp.user != "");
        const logo_background = require('../../assets/images/blurred_lines.jpeg');
        const route = props.navigation.getState();
        const user = authenticationStore.userInfo.user;
        const mySubscriptions = formsStore.mySubscriptions();
        const subscriptions = JSON.parse(JSON.stringify(mySubscriptions.subscriptions)).sort((a, b) => a.name.localeCompare(b.name));
        const source = user.hasProfile ? { uri: (Platform.OS == "web" || true ? user.profileUrl : `file://${FileSystem.documentDirectory}/profile-${authenticationStore.userIdLogged}.jpg?${Date.now()}`) } : require("../../assets/images/logo_circle.png");
        const workflow_count = { simple: 0, ptw: 0 };

        const checkMustSync = async () => {
            setMustSync((await Storage.getItem("must_sync")) != undefined);
            console.log(mustSync)
        }

        itemsStore.myItems().reduce((_state, _item) => {
            if (_item.statusPTW && _item.status == "draft") {
                if (subscriptions.find(dept => dept.id == _item.deptId && dept.forms.find(f => f.id == _item.formId)?.type == "workflow"))
                    _state.simple += 1;
                else
                    _state.ptw += 1;
            }
            return _state;
        }, workflow_count)
        const depts = subscriptions.reduce((_depts, _d) => {
            _d.forms = _d.forms.reduce((_forms, _f) => {
                if (_f.type == undefined)
                    _forms.push(_f);
                return _forms;
            }, []);
            if (_d.forms.length > 0)
                _depts.push(_d);
            return _depts;
        }, []);
        return (
            <View style={{ flex: 1 }} maxHeight={dimensions.height}>
                <View style={$logoContainer}>

                    <ImageBackground source={logo_background} style={$userBackImage}>
                        <VStack>
                            <HStack alignItems={"center"}>
                                <Image alt="useravaar" source={source} style={$userImage} />
                                <Pressable onPress={() => props.navigation.navigate("Settings", { fromForm: false })} style={{ paddingBottom: 10 }}>
                                    <Text style={{ color: "#fff", textAlign: "center" }}>{user?.firstName} {user?.lastName}
                                        <Icon onPress={() => props.navigation.navigate("Settings")} as={MaterialIcons} size={"md"} color="#fff" name="person" />
                                    </Text>
                                    <Text style={{ color: "#fff", textAlign: "center", }}>{customApp.profileHidden ? null : user?.email} </Text></Pressable>
                            </HStack>


                        </VStack>
                    </ImageBackground>
                </View>
                <View style={{ marginVertical: 0, flex: 4, }} >
                    <HStack borderBottomWidth={1} borderBottomColor={"light.300"} px="3">
                        <Pressable _pressed={{ bg: "secondary.600:alpha.20" }}
                            onPress={async () => { Storage.removeItem("must_sync"); await itemsStore.upload({}); formsStore.getSubscriptions(); checkMustSync() }}>
                            <HStack space={[2, 3]} pl="1" py="2" justifyContent="center" alignItems={"center"} >
                                <Icon name="cloud-sync-outline" as={MaterialCommunityIcons} color={"closed.400"} />
                                <Text >
                                    Sincronizar
                                </Text>
                                {mustSync ?
                                    <Badge
                                        colorScheme="yellow" rounded="full" mb={-5} ml={0}
                                        zIndex={1}
                                        variant="solid" alignSelf="flex-start" _text={{
                                            fontSize: "2xs"
                                        }}>
                                        !
                                    </Badge> : null
                                }
                                <Spacer />
                            </HStack>
                        </Pressable><Spacer></Spacer>
                        <View width={"0.5"} borderRightWidth={1} borderRightColor={"light.300"} ></View>
                        <Spacer></Spacer>
                        <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} onPress={() => { props.navigation.navigate("Settings"); setRender(renderMe()); }}>
                            <HStack space={[2, 2]} pl="1" py="2" justifyContent="center" alignItems={"center"} >
                                <Icon name="person" as={Ionicons} color={"closed.400"} />
                                <Text >
                                    Configuraciones
                                </Text>
                                <Spacer />
                            </HStack>
                        </Pressable>

                    </HStack>
                    <DrawerContentScrollView style={{ flex: 1 }} {...props}>
                        <Box borderBottomWidth="0" borderColor="muted.800" pl={["0", "4"]} pr={["0", "5"]} py="0">
                            <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} onPress={() => { props.navigation.navigate("TasksList"); setRender(renderMe()); }}>
                                <HStack space={[2, 3]} justifyContent="center" alignItems={"center"} marginY="2.5" style={{ paddingLeft: 10, paddingRight: 5 }} borderBottomWidth={route.routes[route.index].name == "TasksList" ? 2 : 0} borderBottomColor="secondary.600:alpha.50">
                                    <Icon name="calendar-outline" as={Ionicons} color={"closed.400"} />
                                    <Text bold>
                                        Tareas
                                    </Text>
                                    <Spacer />
                                </HStack>
                            </Pressable>
                            <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} onPress={() => { props.navigation.navigate("WFHome"); setRender(renderMe()); }}>
                                <HStack space={[2, 3]} justifyContent="center" alignItems={"center"} marginY="2.5" style={{ paddingLeft: 10, paddingRight: 5 }} borderBottomWidth={route.routes[route.index].name == "WFHome" ? 2 : 0} borderBottomColor="secondary.600:alpha.50">
                                    <Icon name="workflow" as={Octicons} color={"closed.400"} />
                                    <Text bold>
                                        Flujos de trabajo
                                    </Text>
                                    <Spacer />
                                    {workflow_count?.simple == 0 ? <></> : <Badge colorScheme={"danger"} style={{ height: 25, borderRadius: 10, justifyContent: "center" }}><Text color={"white"}>{workflow_count.simple}</Text></Badge>}
                                </HStack>
                            </Pressable>
                            {/* <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} onPress={() => {props.navigation.navigate("PTWHome"); setRender(renderMe()); }}>
                                <HStack space={[2, 3]} justifyContent="center" alignItems={"center"} marginY="2.5" style={{ paddingLeft: 10, paddingRight: 5 }} borderBottomWidth={route.routes[route.index].name == "PTWHome" ? 2 : 0} borderBottomColor="secondary.600:alpha.50">
                                    <Icon name="order-bool-descending-variant" as={MaterialCommunityIcons} color={"closed.400"} />
                                    <Text bold>
                                        Permisos de Trabajo
                                    </Text>
                                    <Spacer />
                                    {workflow_count?.ptw==0?<></>:<Badge colorScheme={"danger"} style={{ height: 25, borderRadius: 10, justifyContent: "center" }}><Text color={"white"}>{workflow_count?.ptw}</Text></Badge>}
                                </HStack>
                            </Pressable> */}
                        </Box>
                        <View bgColor={"light.100"} style={{ padding: 12 }} >
                            <Text color={"secondary.400"} style={[{ fontSize: 16 }]}>Departamentos</Text>
                        </View>
                        <View style={{ paddingLeft: 10, paddingRight: 5 }}>
                            {depts.map((dept: any) => {
                                return <Box key={dept.id} borderBottomWidth="0" borderColor="muted.800" px="0">
                                    <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} py="2" onPress={() => { props.navigation.navigate("FormsList", { deptId: dept.id }); setRender(renderMe()); }} >
                                        <HStack space={[2, 3]} justifyContent="center" alignItems={"center"} borderBottomWidth={["FormsList", "ItemsList", "ItemDetails"].includes(route.routes[route.index].name) && route.routes[route.index].params.deptId == dept.id ? 2 : 0} borderBottomColor="secondary.600:alpha.50">
                                            <IconButton variant="ghost" key="actionButton-closeItem" p="0"
                                                icon={<Icon as={Ionicons} color={"closed.400"}
                                                    name={expandForms && ["FormsList", "ItemsList", "ItemDetails"].includes(route.routes[route.index].name) && route.routes[route.index].params.deptId == dept.id ? "caret-down" : "caret-forward"}
                                                />}
                                                onPress={() => setExpandForms(!expandForms && dimensions.height > 800)}
                                            />
                                            <Text width={"70%"} bold>
                                                {dept.name}
                                            </Text>
                                            <Spacer />
                                            <Badge colorScheme={"info"} style={{ height: 25, borderRadius: 10, justifyContent: "center" }}><Text color={"white"}>{dept.forms?.length}</Text></Badge>
                                        </HStack>
                                    </Pressable>
                                    {expandForms && ["FormsList", "ItemsList", "ItemDetails"].includes(route.routes[route.index].name) && route.routes[route.index].params.deptId == dept.id ?
                                        <VStack>
                                            {dept.forms.sort((a, b) => a.name.localeCompare(b.name)).map((form: any) => {
                                                return <Box key={form.id} borderBottomWidth="0" borderColor="muted.800" px="0">
                                                    <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} py="2" onPress={() => { props.navigation.navigate((form.type ? (form.type == "workflow" ? "WFHome" : "PTWHome") : "ItemsList"), (form.type ? { rnd: 1 } : { deptId: dept.id, formId: form.id, forReview: false })); setRender(renderMe()); }} >
                                                        <HStack marginLeft="25" justifyContent="center" alignItems={"center"} borderBottomWidth={["ItemsList", "ItemDetails"].includes(route.routes[route.index].name) && route.routes[route.index].params.formId == form.id ? 2 : 0} borderBottomColor="secondary.600:alpha.50">
                                                            <Icon name={route.routes[route.index].params.formId == form.id ? "format-list-checks" : ""}
                                                                as={MaterialCommunityIcons} color={"closed.400"} />
                                                            <Text width={"100%"} fontSize={"xs"}>
                                                                {form.name}
                                                            </Text>
                                                        </HStack>
                                                    </Pressable>
                                                    <VStack>

                                                    </VStack>
                                                </Box>
                                            })}
                                        </VStack>
                                        :
                                        <></>}
                                </Box>
                            })}
                        </View>
                    </DrawerContentScrollView>
                </View>
                <Button display={isFieldForms ? "none" : "flex"} colorScheme={"danger"} leftIcon={<Icon as={SimpleLineIcons} name="logout" size="sm" />}
                    onPress={() => authenticationStore.signOut()}>
                    Cerrar Sesion
                </Button>
                <HStack bgColor={"black"} width={"100%"}><Text color="white" fontSize="10" ml={1}>{customApp.version}</Text><Spacer /><Text color="white" fontSize="10" ml={1}>ult. sinc.: {new Date(mySubscriptions.lastSync).getYYYYMMddHHmmss()}</Text></HStack>


            </View>)
    };

    React.useEffect(() => {
        setRender(renderMe());
        let _disposerOnPatch = onPatch(formsStore, patch => {
            if ((patch.op == "replace" && patch.path.includes("/lastSync")) || (patch.op == "add" && patch.path.includes("/subscriptionsByUsersDB/") && patch.value?.lastSync != undefined))
                setTimeout(() => setRender(renderMe()), 300);
        })
        return () => {
            _disposerOnPatch();
        }

    }, [])
    React.useEffect(() => {
        setRender(renderMe());
    }, [expandForms])

    return rendered
})

const $userImage: ImageStyle = {
    width: 80,
    height: 80,
    marginVertical: 10,
    borderRadius: 200 / 2,
}

const $logoContainer: ViewStyle = {
    flex: 2, alignItems: "center", maxHeight: 100
}


const $userBackImage: ImageStyle = {
    flex: 1, width: "100%", maxHeight: 100,
    justifyContent: "center",
    alignItems: "center",
}