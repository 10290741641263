import { Pressable,View } from "react-native";
import PropTypes from 'prop-types';
const Spacer = ({horizontal, size}) => {
    const defaultValue = 'auto';
  
    return (
        <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-end"
        }}
      ></View>
    );
  };
  
  Spacer.propTypes = {
    size: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    horizontal: PropTypes.bool,
  };
  
  Spacer.defaultProps = {
    horizontal: false,
  };
  
  
  export default Spacer;