import React, { FC } from 'react';
import { Platform } from 'react-native'
import { Box, HStack, Icon, Pressable, Spacer, Text, VStack } from 'native-base';
import DateTimePickerModal from "react-native-modal-datetime-picker";

import { Ionicons } from "@expo/vector-icons";

import { ElementBase, IElementBaseProps, initializeElement } from '..';

export interface myDateTimePicker {
    type: ('date' | 'time'),
    value?: string,
    onChange?: Function,
    min?: string,
    max?: string,
}

const WebDateTimePicker: FC<myDateTimePicker> = (props) => {    
    return React.createElement('input', {
        type: props.type,
        value: props.type == "date" ? props.value?.substring(0, 10) : props.value?.substring(11),
        onChange: (value) => {
            if (props.type == "time") {
                props.onChange(value.currentTarget.value == "" ? null : new Date().getYYYYMMdd().concat("T", value.currentTarget.value))
            } else {
                var d = new Date(value.currentTarget.value);
                if (!(d instanceof Date))
                    props.onChange(null);

                if (props.min && new Date(props.min.concat("T00:00:00")).getTime() > new Date(value.currentTarget.value.concat("T00:00:00")).getTime())
                    value.currentTarget.value = props.min;
                if (props.max && new Date(props.max.concat("T00:00:00")).getTime() < new Date(value.currentTarget.value.concat("T00:00:00")).getTime())
                    value.currentTarget.value = props.max;
                if (value.currentTarget.value != "") {
                    props.onChange(new Date(value.currentTarget.value.concat("T00:00:00")).toISOString());
                }
                else
                    props.onChange(null);
            }
        },
        min: props.min, max: props.max,
        style: { height: 30, padding: 5, border: 0 }
    })
}

const MovileDateTimePicker: FC<myDateTimePicker> = (props) => {
    const [show, setShow] = React.useState(false);
    return <VStack>
        <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }}
            onPress={() => { setShow(!show) }} >
            <HStack py="3" >
                <Icon as={Ionicons} mr="3" name={props.type == "time" ? "time-outline" : "calendar-outline"} size="md" />
                <Text>{props.type == "time" ? props.value.substring(11, 16) : props.value.substring(0, 10)}</Text>
                <Spacer />
            </HStack>
        </Pressable>
        <DateTimePickerModal
            isVisible={show}
            mode={props.type}
            date={new Date()}
            minimumDate={props.min ? new Date(props.min) : undefined}
            maximumDate={props.max ? new Date(props.max) : undefined}
            is24Hour={true}
            onConfirm={(date) => {
                setShow(false);
                if (props.type == "time")
                    props.onChange(date.getYYYYMMddHHmmss());
                else
                    props.onChange(date.getYYYYMMdd().concat("T00:00:00"));
            }}
            onCancel={() => setShow(false)}
        />
    </VStack>
}

export const DateTimeElement: FC<IElementBaseProps> = (({ ...props }) => {
    const {
        itemsStore, element,
        payload, checkAndSetPayload, errorList, setErrorList
    } = initializeElement(props);

    let min = undefined;
    let max = undefined;
    let today = new Date();
    let todayFuture = new Date();
    if (element.type == "time") {
        let allowPrevious = element.templateData.allow_old_times || element.validation.allowPrevious;
        min = allowPrevious ?
            ((element.validation.maxPrevious || 0) == 0 ? undefined
                : today.setHours(today.getHours() - element.validation.maxPrevious))
            : today;

    } else {
        let allowPrevious = element.templateData.allow_old_dates || element.validation.allowPrevious;
        min = allowPrevious ?
            ((element.validation.maxPrevious || 0) == 0 ? undefined
                : new Date(today.setDate(today.getDate() - element.validation.maxPrevious)).getYYYYMMdd())
            : today.getYYYYMMdd();
        max = element.validation.allowFuture ?
            ((element.validation.maxFuture || 0) == 0 ? undefined
                : new Date(todayFuture.setDate(todayFuture.getDate() + element.validation.maxFuture)).getYYYYMMdd())
            : todayFuture.getYYYYMMdd();

        if (min?.length > 0)
            min = min.concat("T12:00:00");
        if (max?.length > 0)
            max = max.concat("T12:00:00")
    }

    const handleChange = (date: string | undefined) => {
        date = date == undefined ? "" : date.toString();
        checkAndSetPayload(date);
    }
    React.useEffect(() => {
        if (element.autoSet == true && payload.value ==undefined)
            checkAndSetPayload((new Date()).getYYYYMMddHHmmss());
    }, [])


    return <ElementBase errors={errorList} element={element} forReview={props.forReview} >
        {props.forReview ?
            <Text style={{ color: (element.templateData.textColor ? element.templateData.textColor : "#000") }}>
                {element.type == "time" ? payload.value?.substring(11, 16) : payload.value?.substring(0, 10)}
            </Text>
            :
            <Box>
                {Platform.OS == "web" ?
                    <WebDateTimePicker onChange={handleChange} type={element.type} value={payload.value || ''} min={min} max={max}></WebDateTimePicker>
                    :
                    <MovileDateTimePicker onChange={handleChange} type={element.type} value={payload.value || ''} min={min} max={max}></MovileDateTimePicker>
                }
            </Box>
        }
    </ElementBase>
});