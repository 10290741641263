import { Box, HStack, Icon, Input, Spacer, Switch, Text, VStack } from 'native-base';
import React, { FunctionComponent } from 'react';
import { ElementBase, IElementBaseProps, initializeElement } from '..';

export const TimestampElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const {
        itemsStore, element,
        payload, setPayload, errorList, setErrorList
    } = initializeElement(props);

    const toggleSwitch = (val: boolean) => {
        let errorElement = itemsStore.getErrorElement(element, val ? new Date() : undefined);
        setErrorList(errorElement.errorList)
        setPayload({ ...payload, value: val ? new Date().toISOString() : undefined, valid: errorElement.valid });
    }
    React.useEffect(() => {
        if (element.autoSet == true && payload.value == undefined)
            toggleSwitch(true);
    }, []);
    const toggle = props.forReview || itemsStore.itemActive.status != "draft" ? undefined : (<Switch value={payload.value ? true : false} onToggle={toggleSwitch} colorScheme="success" />)
    return <ElementBase errors={errorList} element={element} rigthButton={toggle} forReview={props.forReview} >
        <Text color={element.templateData?.textColor || "#000"}>
            {payload?.value ? (new Date(payload.value).getYYYYMMddHHmmss()) : ' '}
        </Text>
    </ElementBase>
}