import { Instance, SnapshotIn, SnapshotOut, flow, types } from "mobx-state-tree"
import { withSetPropAction } from "./helpers/withSetPropAction"
import { withStatusModel } from "./helpers/withStatusModel";
/**
 * Model description here for TypeScript hints.
 */
import { IntegrationsOfflineByUserModel, IntegrationsCacheByUserModel } from "./helpers/typesModels";
import { Platform } from "react-native";


export const IntegrationsStoreModel = types
  .model("IntegrationsStore")
  .props({
    integrationsOfflineByUserModel: types.array(IntegrationsOfflineByUserModel),
    integrationsCacheByUserModel: types.array(IntegrationsCacheByUserModel)
  })
  .actions(withSetPropAction)
  .extend(withStatusModel)
  .actions((store) => ({
    myIntegration: (integrationId: string) => {
      let idx = store.integrationsOfflineByUserModel.findIndex(
        (integration) =>
          // @ts-ignore
          integration.userId == store.parent.authenticationStore.userIdLogged
          && integration.id == integrationId
      );

      if (idx >= 0)
        // @ts-ignore
        return { idx, object: JSON.parse(JSON.stringify(store.integrationsOfflineByUserModel[idx])) };
      return null;
    },
    myIntegrationCache: (integrationId: string, dynamicParams: string) => {
      let idx = store.integrationsCacheByUserModel.findIndex(
        (integration) =>
          // @ts-ignore
          integration.userId == store.parent.authenticationStore.userIdLogged
          && integration.id == integrationId
          && integration.dynamicParams == dynamicParams
      );

      if (idx >= 0)
        // @ts-ignore
        return { idx, object: JSON.parse(JSON.stringify(store.integrationsCacheByUserModel[idx])) };
      return null;
    },
  }))
  .actions((store) => ({
    updateOffline: (integrationId: string, definition: any, response: any) => {
      console.log("init updIntegrationOffline", integrationId);
      let myIntegration = store.myIntegration(integrationId);
      let list = store.integrationsOfflineByUserModel?.toJSON() || [];
      if (myIntegration) {
        console.log("Integracion Existente", integrationId)
        myIntegration.object.definition = definition || myIntegration.object.definition;
        myIntegration.object.response = response || myIntegration.object.response
        myIntegration.object.lastSync = Date.now();
        list[myIntegration.idx] = myIntegration.object;
      } else {
        console.log("Integracion Nueva", integrationId)
        let newIntegration = IntegrationsOfflineByUserModel.create({
          id: integrationId, userId: store.parent.authenticationStore.userIdLogged,
          definition, response, lastSync: Date.now()
        });
        list.push(newIntegration);
      }
      store.setProp("integrationsOfflineByUserModel", list);
      console.log("end updIntegrationOffline", integrationId);
      return true;
    },
    updateCache: flow(function* updateCache(integrationId: string, dynamicParams: any, response: any) {
      console.log("init updateCache", integrationId);
      let myIntegration = store.myIntegrationCache(integrationId, dynamicParams);
      let list = store.integrationsCacheByUserModel?.toJSON() || [];
      if (myIntegration) {
        console.log("Integracion Existente", integrationId)
        myIntegration.object.dynamicParams = dynamicParams || myIntegration.object.dynamicParams;
        myIntegration.object.response = response || myIntegration.object.response
        myIntegration.object.lastSync = Date.now();
        list[myIntegration.idx] = myIntegration.object;
      } else {
        console.log("Integracion Nueva", integrationId)
        let newIntegration = IntegrationsCacheByUserModel.create({
          id: integrationId, userId: store.parent.authenticationStore.userIdLogged,
          dynamicParams, response, lastSync: Date.now()
        });
        list.push(newIntegration);
      }
      store.setProp("integrationsCacheByUserModel", list);
      console.log("end updateCache", integrationId);
      return true;
    }),
    removeOffline: (integrationId: string) => {
      let myIntegration = store.myIntegration(integrationId);;
      if (myIntegration)
        store.integrationsOfflineByUserModel.splice(myIntegration.idx, 1);
    },
    removeAll: (integrationId: string) => {
      store.integrationsOfflineByUserModel.splice(0);
    },
  }))
  .actions((store) => ({
    downloadOffline: flow(function* downloadOffline(subscriptions: [any]) {
      let lstIntegrationId: string[] = [];
      subscriptions.forEach(dept => {
        dept.forms.forEach(form => {
          form.pages.forEach(page => {
            page.fields.forEach(field => {
              if (field.integration && field.integration.id)
                lstIntegrationId.indexOf(field.integration.id) === -1 ? lstIntegrationId.push(field.integration.id) : null;
              field.nodes?.forEach(node => {
                if (node.integration && node.integration.id)
                  lstIntegrationId.indexOf(node.integration.id) === -1 ? lstIntegrationId.push(node.integration.id) : null;
                node.nodes?.forEach(node2 => {
                  if (node2.integration && node2.integration.id)
                    lstIntegrationId.indexOf(node2.integration.id) === -1 ? lstIntegrationId.push(node2.integration.id) : null;
                });
              });
            });
          });
        });
      });
      console.log("Integrations Offline", lstIntegrationId)
      const loadInfoInit = store.addLoadingInfo({ title: "Integraciones Offline a Sincronizar: ".concat(lstIntegrationId.length.toString()), spinner: lstIntegrationId.length > 0 })

      if (lstIntegrationId.length == 0)
        return;
      const loadInfoInit2 = store.addLoadingInfo({ title: "... descargando configuraciones ..." })
      const respInts = yield store.api().getIntegrations(lstIntegrationId);
      if (respInts && respInts.status == 200) {
        const jsonInts = yield respInts.json();
        yield Promise.all(jsonInts.map(async (integration: any, idx: number) => {
          if (integration.configuration.connection.allowOffline) {
            const loadInfoInitX = store.addLoadingInfo({ title: " ... ".concat(integration.name) })
            let myIntegration = store.myIntegration(integration.id) || { object: undefined };
            if (myIntegration.object?.lastSync != undefined && ((Date.now() - myIntegration.object.lastSync) / (1000 * 60 * 60)) < (integration.configuration.connection.refreshOffline || 8))
              return;
            console.log("Integration Offline", idx, integration.name, "lastSync", myIntegration.object?.lastSync, "get");
            const respInt = await store.api().getIntegration(integration.id, {}, true);
            console.log("Integration Offline", idx, integration.name, "end get");
            if (respInt) {
              const wsResp = await respInt.text();
              //console.log("Integration Offline", integration.name, wsResp)
              if (respInt.status == 200)
                store.updateOffline(integration.id, integration, wsResp);
              store.updLoadingInfo({ id: loadInfoInitX, spinner: false })
            }
          } else
            store.removeOffline(integration.id);
        }));
        console.log("TERMINO TODAS LAS OFFLINE")
        store.updLoadingInfo({ id: loadInfoInit, spinner: false });
      }
    }),
    getIntegration: flow(function* getIntegration(integrationId, dynamicParams) {
      console.log("getIntegration", 2)
      let integration = store.myIntegration(integrationId);
      if (integration && integration.object?.definition?.configuration?.connection?.allowOffline) {
        console.log("getIntegration", "OFFLINE", integrationId)
        async function afterHook(dataString, params) {
          for (const param in params) {
            if (params[param] === "{currentUser.email}")
              params[param] = store.parent.authenticationStore.userInfo.email;
            if (params[param] === "{currentUser}")
              params[param] = store.parent.authenticationStore.userInfo;
            if (params[param] === "{currentUser.id}")
              params[param] = store.parent.authenticationStore.userIdLogged;
            if (params[param] === "{formId}")
              params[param] = store.parent.itemsStore.itemActive.formId;
            if (params[param] === "{deptId}")
              params[param] = store.parent.itemsStore.itemActive.deptId;
          }
          let data = dataString;
          if (integration.object.definition.configuration.type == "json")
            try {
              data = await JSON.parse(dataString);
            } catch (e) {
              data = {};
            }
          else {
            //XML PARSER
          }
          try {
            let afterHookLibs = 'function getGPSDistance(lat1, lon1, lat2, lon2) {if ((lat1 == lat2) && (lon1 == lon2)) {return 0;}else {var radlat1 = Math.PI * lat1/180; var radlat2 = Math.PI * lat2/180; var theta = lon1-lon2; var radtheta = Math.PI * theta/180; var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta); if (dist > 1) { dist = 1; } dist = Math.acos(dist); dist = dist * 180/Math.PI; dist = dist * 60 * 1.1515 * 1.609344; return dist; } };';
            afterHookLibs += '\nfunction findField(item,fieldId) { function _findField(lstFields) { return lstFields.reduce(function (obj, el) { if (el.id == fieldId) return el; if (obj == undefined && el.nodes) obj = _findField(el.nodes); return obj; }, undefined); } return item.pages.reduce(function (obj, page) { if (obj) return obj; return _findField(page.fields); }, undefined); };'
            afterHookLibs += '\nfunction table_getListOfColumn(table, colNumber, hasHeaderRow, uniqueValues,conditionCol,conditionValue) { return table.reduce(function (list, row, idy) { if (idy == 0 && hasHeaderRow) return list; var value = row[colNumber]; if (value && (!uniqueValues || list.indexOf(value) < 0)) { if(conditionCol==undefined) list.push(value); else if(row[conditionCol].toString().toLowerCase()==conditionValue.toString().toLowerCase()) list.push(value); } return list; }, []).sort(); };';
            afterHookLibs += '\nfunction csv_getTable(data, colSeparator) { return data.split("\\n").map(function(row){return row.split(colSeparator)}); };';
            afterHookLibs += '\nfunction csv_getListOfColumn(data,colSeparator, colNumber, hasHeaderRow, uniqueValues,conditionCol,conditionValue){ return table_getListOfColumn(csv_getTable(data,colSeparator),colNumber, hasHeaderRow, uniqueValues,conditionCol,conditionValue); };';
            afterHookLibs += '\nfunction gsheet_getListOfColumn(data,colNumber, hasHeaderRow, uniqueValues,conditionCol,conditionValue) { return table_getListOfColumn(data.values,colNumber, hasHeaderRow, uniqueValues,conditionCol,conditionValue); };';
            let codeJS = afterHookLibs.concat(";\n ",
              "function runMe(data,params){", integration.object.definition.configuration.afterHook, ";};", "let data=", JSON.stringify(data), ";let params=", JSON.stringify(params), ";runMe(data,params)");
            let xzl = eval?.(codeJS);
            return xzl;
          } catch (e) {
            console.log("afterHook", e.message);
            return null;
          }
        }
        return yield afterHook(JSON.parse(integration.object.response).response,
          { ...integration.object?.definition?.configuration?.connection?.staticParams, ...integration.object?.definition?.configuration?.connection?.dynamicParams, ...dynamicParams }
        );
      } else {
        for (const param in dynamicParams) {
          if (dynamicParams[param] === "{formId}")
            dynamicParams[param] = store.parent.itemsStore.itemActive.formId;
          if (dynamicParams[param] === "{deptId}")
            dynamicParams[param] = store.parent.itemsStore.itemActive.deptId;
        }

        const respInt = yield store.api().getIntegration(integrationId, dynamicParams, false);
        if (respInt) {
          //console.log("Integration Online ", integrationId)
          if (respInt.status == 200) {
            const responseIntegration = yield respInt.json();
            yield store.updateCache(integrationId, JSON.stringify(dynamicParams), JSON.stringify(responseIntegration.response));
            return responseIntegration.response;
          } else {
            let fromCache = store.myIntegrationCache(integrationId, JSON.stringify(dynamicParams));
            if (fromCache)
              return fromCache.object.response;
            return null;
          }
        }
        console.log("dasdasd", respInt)
        return null;
      }
    })
  })) // eslint-disable-line @typescript-eslint/no-unused-vars  

export interface IntegrationsStore extends Instance<typeof IntegrationsStoreModel> { }
export interface IntegrationsStoreSnapshotOut extends SnapshotOut<typeof IntegrationsStoreModel> { }
export interface IntegrationsStoreSnapshotIn extends SnapshotIn<typeof IntegrationsStoreModel> { }
export const createIntegrationsStoreDefaultModel = () => types.optional(IntegrationsStoreModel, {})
