import React, { FC, FunctionComponent } from "react"
import { observer } from "mobx-react-lite"

import { SwipeRow } from 'react-native-swipe-list-view';


import { AlertDialog, IconButton, Spacer, } from "native-base"

import {
  StatusBar, Badge, Button, Icon, ButtonText, ButtonIcon, HStack, VStack, Text,
  View, AddIcon, Center, ScrollView, Box, Pressable, BadgeText
} from "@gluestack-ui/themed"


import { MaterialIcons, Ionicons, MaterialCommunityIcons, FontAwesome5,FontAwesome } from "@expo/vector-icons";

import { HomeStackParamList, useBackButtonHandler } from "../../navigators"
import { DrawerScreenProps } from "@react-navigation/drawer"
import { useStores } from "../../models"
import { ITEM_STATUS } from "../../voolks/types";
import { getIconItem, getItemTitle, openPDF } from "../../utils/itemCommonFunctions";

export const ItemsListScreen: FC<DrawerScreenProps<HomeStackParamList, "ItemsList">> = observer(function FormsListScreen({ navigation, route }) {
  const fcName = "ItemsListScreen";
  console.log(fcName);
  const { formsStore, itemsStore, statusStore, tasksStore } = useStores();
  const [alertDialog, setAlertDialog] = React.useState(undefined);
  const [showCheck, setShowCheck] = React.useState<boolean>(false);
  const [itemsSelected, setItemsSelected] = React.useState<any[]>([]);
  let department;
  if(route.params==undefined || route.params?.deptId==undefined)
    department = formsStore.mySubscriptions().subscriptions[0];
  else
    department = formsStore.mySubscriptions().subscriptions.find((dept: any) => dept.id == route.params?.deptId);
  if (department == undefined) {
    navigation.navigate("Settings");
    return <><Text></Text></>
  }
  const form = department.forms.find((form: any) => form.id == route.params.formId);
  if (form == undefined) {
    navigation.navigate("FormsList", { deptId: department.id });

    return <><Text></Text></>
  }
  const taskId = route.params.taskId;
  const tasks = taskId != undefined ? tasksStore.myTasks()?.tasks : [];
  const task: any = tasks?.find((item: any) => item.id == taskId)
  const swipeRows: any[] = [];
  const lastPressedBtn = React.useRef(0);
  let swipeRow_selected: any;
  let my_items: any = React.useMemo(() => {
    return itemsStore.myItems({ deptId: department.id, formId: form.id }).reverse();
  }, [itemsStore.itemsByUsersDB.length, route.params.formId]);
  const goBack = () => {
    if (taskId)
      navigation.navigate("TaskDetails", { taskId: taskId, fromTaskList: taskId != undefined });
    else
      navigation.navigate("FormsList", { deptId: department.id });

  }

  useBackButtonHandler(goBack);

  const newItem = async () => {
    console.log(fcName, "newItem", "init");
    if ((new Date().getTime() - lastPressedBtn.current) < 3000)
      return;
    lastPressedBtn.current = new Date().getTime();
    await itemsStore.new(department.id, form.id, taskId)
    _openItem();
  };

  const openItem = async (itemSelected: any = undefined) => {
    if ((new Date().getTime() - lastPressedBtn.current) < 3000)
      return;
    lastPressedBtn.current = new Date().getTime();
    _openItem(itemSelected);

  }

  const _openItem = async (itemSelected: any = undefined) => {
    console.log(fcName, "_openItem", "Init");
    if (itemSelected)
      await itemsStore.activateItem(itemSelected)
    if (itemsStore.itemActiveIDX != undefined) {
      let preValues = (task?.payload ?? []).reduce((fields, metadata) => {
        if (metadata?.fieldId)
          fields[metadata.fieldId] = metadata.value;
        return fields;
      }, {});

      await itemsStore.makePayloadFromFormPages(form.pages, preValues);
      navigation.navigate("ItemDetails", {
        itemStartedAt: new Date().getTime(),
        formId: form.id,
        deptId: department.id,
        forReview: itemsStore.itemActive.status != ITEM_STATUS.draft.toString(),
        taskId: taskId,
        pageActive: 0,
        rnd: new Date().getTime()
      })
    }
    console.log(fcName, "_openItem", "Fin");
  }

  const removeItemsDialog = async (item: any = undefined) => {
    let lstIdx: number[] = [];
    let alert = { title: "", message: "" };
    if (item != undefined) {
      let itemIdx: number = itemsStore.getIDXofItem(item)
      if (itemIdx > -1) {
        lstIdx.push(itemIdx);
      }
      alert.title = "Borrar registro";
      alert.message = `¿Estas seguro de borrar el registro  ${getItemTitle(form, item)}?`;
    } else {
      let lstTitles: string[] = [];
      itemsSelected.forEach((item) => {
        let itemIdx: number | undefined = itemsStore.getIDXofItem(item)
        if (itemIdx != undefined) {
          lstTitles.push(getItemTitle(form, item));
          lstIdx.push(itemIdx)
        }
      })
      alert.title = "Borrar multiples registros";
      alert.message = `¿Estas seguro de borrar los registros  ${lstTitles.join(", ")}?`;
    }
    if (lstIdx.length > 0) {
      setAlertDialog({
        ...alert,
        action: () => {
          swipeRows.map(async (row) => {
            await row?.closeRow();
          });
          lstIdx.sort((a, b) => { return b - a; }).forEach(idx => itemsStore.removeItem(idx));
          setShowCheck(false);
        }
      })
    }
  }

  const checkAll = async () => {
    let selItems = Array.from(itemsSelected);
    await my_items.map(async (item: any) => {
      if (isItemSelected(item)) {
        selItems.splice(selItems.indexOf(item), 1)
      } else {
        selItems.push(item);
      }
    });
    setItemsSelected(selItems);
  }

  const isItemSelected = (item: any) => {
    return itemsSelected.includes(item)
  }
  const selectItem = (item: any) => {
    console.log(new Date().toISOString(), "selectItem IN");
    let selItems = Array.from(itemsSelected);
    if (isItemSelected(item)) {
      selItems.splice(selItems.indexOf(item), 1)
    } else {
      selItems.push(item);
    }
    setItemsSelected(selItems);
    console.log(new Date().toISOString(), "selectItem OUT");
    return selItems;
  }
  const ItemsListRows: FunctionComponent = () => {
    return <>
      {my_items
        .map((item: any, idx: number) => {
          return (
            <SwipeRow key={`SwipeRow${idx}`} leftOpenValue={110} rightOpenValue={-55}
              ref={(c) => { swipeRows[idx] = c; }}
              onRowOpen={() => {
                if (swipeRow_selected && swipeRow_selected !== swipeRows[idx]) { swipeRow_selected.closeRow(); }
                swipeRow_selected = swipeRows[idx];
              }}

            >
              <View style={{ minHeight: 55, flexWrap: "wrap", flexDirection: "row" }}>
                <View >
                  <Button isDisabled={item.status != ITEM_STATUS.sent} borderRadius={0} width={55} height={55} p="$1" bgColor={"$success500"}
                    variant="solid"
                    key="itemsStore-openPDF"
                    onPress={() => openPDF(itemsStore, item)}
                  >
                    <ButtonIcon as={FontAwesome5} name="file-pdf" fontWeight="$extrabold" size="xl" color="#FFF" />
                  </Button>
                </View>
                <View >
                  <Button isDisabled={item.status != ITEM_STATUS.sent} borderRadius={0} width={55} height={55} p="$1" bgColor={"$yellow500"}
                    variant="solid"
                    key="itemsStore-openPDF"
                    onPress={() => openPDF(itemsStore, item, true)}
                  >
                    <ButtonIcon as={Ionicons} name="mail" fontWeight="$extrabold" size="xl" color="#FFF" />
                  </Button>
                </View>
                <Spacer></Spacer>                
                <View alignSelf="center" bgColor={"$danger500"} >
                  <Button width={55} height={55} p="$1" bgColor={"$danger500"}
                    variant="solid"
                    key="itemsStore-removeItemsDialog"
                    onPress={() => removeItemsDialog(item)}
                  >
                    <ButtonIcon as={Ionicons} name="trash-outline" fontWeight="$extrabold" size="xl" color="#FFF" />
                  </Button>
                </View>
              </View>
              <View borderBottomWidth={1} borderBottomColor={"$light300"} style={{ minHeight: 55, flexWrap: "wrap", backgroundColor: "white", justifyContent: "center", }}  >
                <HStack paddingVertical={3} width={"$full"} alignItems="center" paddingHorizontal={5}>
                  {getIconItem(item.status)}<Pressable
                    onPress={() => openItem(item)} style={{ flexDirection: "row" }}>
                    <View flexDirection="row" alignItems="center" width={statusStore.windowDimensions.width - (statusStore.windowDimensions.width >= 1500 ? 400 : 80)} >
                      <Text paddingHorizontal={3} textBreakStrategy="simple" size="md">{getItemTitle(form, item)}</Text>
                    </View></Pressable>
                  {item.taskId && taskId == undefined ?
                    <Button width={55} height={55} borderRadius={0} borderWidth={0} p="$1" bgColor={"$white"}
                      variant="outline"
                      key="itemsStore-removeItemsDialog"
                      onPress={() => { navigation.navigate("TaskDetails", { taskId: item.taskId, fromTaskList: false }) }}
                    >
                      <ButtonIcon as={MaterialIcons} name="event" fontWeight="$extrabold" size="xl" color="$secondary400" />
                    </Button>
                    :
                    <></>
                  }
                  <Spacer></Spacer>
                  {showCheck ?
                    (<Pressable onPress={() => { selectItem(item) }} ><Box height={35} width={45} alignItems="center" justifyContent="center">
                      {isItemSelected(item) ? <Icon mx="5" size="lg" name="checkbox-outline" as={Ionicons} /> : <Icon mx="2" size="lg" name="square-outline" as={Ionicons} />}
                    </Box>
                    </Pressable>)
                    : <Pressable
                      onPress={() => openItem(item)} style={{ flexDirection: "row" }}>
                      <Icon name="arrow-forward" as={Ionicons} size="md" /></Pressable>
                  }
                </HStack>
              </View>

            </SwipeRow>

          )
        })}
    </>;

  }
  const someToUpload =
    my_items.reduce((counter, item) => {
      return counter + (item.status == ITEM_STATUS.closed ? 1 : 0)
    }, 0);

  const cancelRef = React.useRef();
  let leftButton = <Button width={50} height={50} p="$1" variant="solid" key="actionButton-nextPage" onPress={goBack}>
    <ButtonIcon as={Ionicons} name="arrow-back" fontWeight="$extrabold" size="xl" color="#FFF" />
  </Button>;


  return (<>
    <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
    <Box bg="primary.400" />
    <HStack bg="$primary400" paddingHorizontal={0} paddingVertical={0} justifyContent="space-between" alignItems="center" w="100%">
      <HStack alignItems="center" maxWidth={statusStore.windowDimensions.width - 140}>
        {leftButton}
        <Text color="white" fontSize={18} marginLeft={1} >
          {form.name}
        </Text>
      </HStack>
      <HStack>
        {someToUpload > 0 ?
          <VStack>
            <Badge h={22} w={22} bg="$red600" borderRadius="$full" mb={-22} mr={0} zIndex={1}
              variant="solid" alignSelf="flex-end" ><BadgeText size="2xs" color="$white">{someToUpload}</BadgeText>
            </Badge>
            <Button width={55} height={55} p="$1" variant="solid" key="itemsStore-upload" onPress={() => itemsStore.upload({ deptId: department.id, formId: form.id })}>
              <ButtonIcon as={MaterialCommunityIcons} name="cloud-upload" fontWeight="$extrabold" size="xl" color="#FFF" />
            </Button>
          </VStack>
          : null
        }
        {showCheck ?
          <Button width={50} height={50} p="$1" variant="solid" key="itemsStore-upload" onPress={checkAll}>
            <ButtonIcon as={MaterialCommunityIcons} name="checkbox-multiple-marked" fontWeight="$extrabold" size="xl" color="#FFF" />
          </Button>
          :
          <Button width={50} height={50} p="$1" variant="solid" key="itemsStore-upload" onPress={newItem}>
            <ButtonIcon as={Ionicons} name="add" fontWeight="$extrabold" size="xl" color="#FFF" />
          </Button>
        }
      </HStack>
    </HStack>
    {task ?
      <HStack key={`r2`} bgColor={"light.200"} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"$light300"} >
        <Icon name="event" size="lg" mr="3" color={"secondary.400"} as={MaterialIcons} />
        <Text size="md" color="secondary.400"> {task.name}</Text>
      </HStack>
      :
      <></>
    }
    <VStack bg="$white" flex={1}>
      {my_items.length == 0 ? <Center margin={"auto"} alignItems="center" width={"100%"} height={"100%"}>
        <Button margin="auto" onPress={newItem} width={"100%"}
          variant="solid"
          action="secondary"
          isDisabled={false}
          isFocusVisible={false}
        >
          <ButtonText>INGRESAR NUEVO REGISTRO </ButtonText>
          <ButtonIcon as={AddIcon} />
        </Button>
      </Center>
        :
        <>
          <ScrollView><ItemsListRows /></ScrollView>
          <Spacer />
          <HStack>
            <Spacer />
            {showCheck && itemsSelected.length > 0 ?
              <Button width={50} height={50} p="$1" borderColor="$danger600"
                variant={"outline"} bgColor={"$white"} key="itemsStore-trashcheckmark"
                onPress={()=>removeItemsDialog()}>
                <ButtonIcon as={Ionicons} name="checkmark" fontWeight="$extrabold" size="xl" color={"$danger400"} />
              </Button>
              :
              <></>}
            <Button width={50} height={50} p="$1" borderColor="$danger600" variant={showCheck ? "solid" : "outline"} bgColor={showCheck ? "$danger400" : "$white"} key="itemsStore-trash" onPress={() => setShowCheck(!showCheck)}>
              <ButtonIcon as={Ionicons} name="trash-outline" fontWeight="$extrabold" size="xl" color={showCheck ? "$white" : "$danger400"} />
            </Button>
          </HStack>
        </>
      }
    </VStack>
    {alertDialog == undefined ? <></> :
      <AlertDialog leastDestructiveRef={cancelRef} isOpen={alertDialog != undefined} >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{alertDialog?.title}</AlertDialog.Header>
          <AlertDialog.Body>{alertDialog?.message}</AlertDialog.Body>
          <AlertDialog.Footer>
            <Button ref={cancelRef} onPress={() => setAlertDialog(undefined)}>
              <ButtonText>Cancelar</ButtonText>
            </Button>
            <Button action="negative" ml={3} onPress={() => { alertDialog?.action(); setAlertDialog(undefined) }}>
              <ButtonText>Aceptar</ButtonText>
            </Button>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    }

  </>
  )
})