import { observer } from "mobx-react-lite"
import React, { FC } from "react"
import { Image, ImageStyle, Pressable, ViewStyle, Modal, Platform, Dimensions, Linking } from "react-native"

import { WebView } from 'react-native-webview';
import { WebView as WebViewForWeb } from 'react-native-web-webview';
import { Input, Icon as Icon2, Button, View, Text, ScrollView } from "native-base"
import { MaterialIcons, FontAwesome5 } from "@expo/vector-icons";


import { setupRootStore, useStores } from "../models"
import { customApp } from "../config";
import StylesApp from "./StylesApp"
import { RegisterFormScreen, ResetForm } from "./RegisterFormScreen"
import OldStore from "../utils/storage/oldStore";
import * as storage from "../utils/storage";
import * as FileSystem from 'expo-file-system';
import * as DocumentPicker from 'expo-document-picker';
import DeviceInfo from "../services/deviceInfo"

const welcomeLogo = require("../../assets/images/logo.png")


// @ts-ignore
export const LoginScreen: FC = observer(function LoginScreen(_props
) {
  const { authenticationStore, statusStore } = useStores();
  const [resetFormVisible, setResetFormVisible] = React.useState(false);
  const [webViewVisible, setWebViewVisible] = React.useState(false);
  const [registerFormVisible, setRegisterFormVisible] = React.useState(false);
  const [helpPTWVisible, setHelpPTWVisible] = React.useState(false);
  const rootStore = useStores()
  let inputPWD_ref = React.createRef()

  if (customApp.user && customApp.user != "") {
    authenticationStore.signIn({ username: customApp.user, password: customApp.pass });
    return <View></View>;
  }

  const focusPass = () => {
    // @ts-ignore
    inputPWD_ref.focus();
  }

  const sendLogs = async () => {
    statusStore.setStatus("pending");
    statusStore.clearLoadingInfo();
    statusStore.addLoadingInfo({ title: "Enviando Logs", spinner: true });
    let allKeys = await OldStore.getAllKeys();
    let _oldStorageJSON: any = {};
    if (allKeys.length > 0) {
      _oldStorageJSON["allKeys"] = JSON.stringify(allKeys);
      for (const key of allKeys) {
        _oldStorageJSON[key] = await OldStore.getItemPart(key) || "";
      }
    }

    let flname = new Date().toISOString().concat("-", customApp.packagename, '-', DeviceInfo.deviceModel, '-', await DeviceInfo.uniqueId());


    let storageJSON: any = {};
    storageJSON = {
      "oldStore": _oldStorageJSON,
      "v3-IntegrationsStore-v1": await storage.getItem("v3-IntegrationsStore-v1") || "",
      "v3-ItemsStore-v1": await storage.getItem("v3-ItemsStore-v1") || "",
      "v3-authStore-v1": await storage.getItem("v3-authStore-v1") || "",
      "v3-formsStore-v1": await storage.getItem("v3-formsStore-v1") || "",
    }
    statusStore.addLoadingInfo({ title: "Enviando Logs de Registros", spinner: true });
    let str = JSON.stringify(storageJSON);
    const data = new FormData();
    if (Platform.OS == "web") {
      let res = await fetch("data:text/plain,".concat(str));
      let blob = await res.blob();
      const file = new File([blob], flname);
      data.append(flname, file, flname)

    } else {
      const filePath = `${FileSystem.documentDirectory}/${flname}.txt`;
      await FileSystem.writeAsStringAsync(filePath, str);
      data.append(flname, {
        name: flname,
        type: "application/octet-stream",
        uri: Platform.OS === 'ios' ? filePath : `file://${filePath}`,
      });
    }
    await fetch(customApp.baseUrl.concat("api/containers/appfield-userlogs/upload/"), {
      method: "post",
      body: data,
      headers: Platform.OS == "web" ? {} : { "Content-Type": "multipart/form-data; " },
    });
    statusStore.setStatus("done");

  }

  const loadLogs = async () => {
    async function process(content) {
      const storageJSON = JSON.parse(content);
      console.log(storageJSON);
      await storage.setItem("IntegrationsStore-v1", storageJSON["v3-IntegrationsStore-v1"]);
      await storage.setItem("ItemsStore-v1", storageJSON["v3-ItemsStore-v1"]);
      await storage.setItem("authStore-v1", storageJSON["v3-authStore-v1"]);
      await storage.setItem("formsStore-v1", storageJSON["v3-formsStore-v1"]);
      await setupRootStore(rootStore)
    }
    const result = await DocumentPicker.getDocumentAsync({ multiple: false, })
    if (result.canceled)
      return;
    console.log(result);
    statusStore.setStatus("pending");
    statusStore.clearLoadingInfo()
    let fileUri = result.assets[0].uri;
    if (fileUri.substring(0, 5) == "data:") {
      //base64
      let res = await fetch(fileUri);
      let blob = await res.blob();
      let reader = new FileReader();
      await reader.readAsText(blob, 'utf8');
      reader.onload = async function () {
        await process(reader.result)
        statusStore.setStatus("done");
      }
    } else {
      let content = await FileSystem.readAsStringAsync(fileUri)
      await process(content);
      statusStore.setStatus("done");

    }

  }

  const [data, setData] = React.useState({
    username: '',
    password: '',
    check_textInputChange: false,
    secureTextEntry: true,
    isValidUser: false,
    isValidPassword: false,
  });

  const handlePasswordChange = (val: string) => {
    setData({
      ...data,
      password: val,
      isValidPassword: (val.trim().length >= 3)
    });
  }

  const handleUserChange = (val: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    val = val.toLowerCase().trim();
    if (val.trim().length >= 4 && re.test(val)) {
      setData({
        ...data,
        username: val,
        check_textInputChange: true,
        isValidUser: true
      });
    } else {
      setData({
        ...data,
        username: val,
        check_textInputChange: false,
        isValidUser: false
      });
    }
  }

  const updateSecureTextEntry = () => {
    setData({
      ...data,
      secureTextEntry: !data.secureTextEntry
    });
  }

  const WebView_onMessage = (message: any) => {
    authenticationStore.signSAML(JSON.parse(message.nativeEvent.data));
  }

  const web_OpenLoginSAML = () => {
    if (Platform.OS == "web") {
      const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600`;
      const url = `${customApp.baseUrl}sso/saml/loginWebApp?urlMS=${customApp.loginSAML?.url}`;
      const newWindow = window.open(url, "Login Ms Azure", params);
      window.addEventListener('message', (event) => {
        if (event.data?.user) {
          authenticationStore.signSAML(event.data);
          newWindow.close();
        }
      });
    } else {
      setWebViewVisible(true);
    }
  }

  const html = `
  <html>
  <head></head>
  <body>
    <h1>Loading Login Provider...</h1>
    <script>
      setTimeout(function () {        
        document.location.href='${customApp.loginSAML?.url}';
      }, 100)
    </script>
  </body>
  </html>
`;



  return (
    <View style={[$container, StylesApp.bgPrimary, { height: (statusStore.windowDimensions.height - 20) }]}>
      <View style={[$topContainer]}>
        <Image style={$welcomeLogo} source={welcomeLogo} resizeMode="contain" />
      </View>
      {(customApp.user && customApp.user != "") ?
        <Text>Ingresando...</Text>
        :
        <ScrollView>
          <View style={[$middleContainer]} >
            <View style={{ alignContent: "center", alignItems: "center" }}>
              <Input borderWidth={data.isValidUser || data.username.length == 0 ? 0 : 1} borderColor="red.600" color="#fff" placeholderTextColor={"#fff"} w="90%"
                autoComplete="email" autoFocus={true} fontSize="xl"
                InputLeftElement={<Icon2 as={<MaterialIcons name="person-outline" />} size="xl" ml="2" color="#fff" />}
                placeholder="Email"
                onChangeText={(val) => handleUserChange(val)}
                onSubmitEditing={focusPass}
              />
            </View>
            <View style={{ alignContent: "center", alignItems: "center" }} my="10">
              <Input ref={ref => inputPWD_ref = ref} marginTop={5} borderWidth={data.isValidPassword || data.password.length == 0 ? 0 : 1} borderColor="red.600" color="#fff" placeholderTextColor={"#fff"} w="90%"
                autoComplete="password" fontSize="xl"
                secureTextEntry={data.secureTextEntry}
                onSubmitEditing={() => authenticationStore.signIn({ username: data.username, password: data.password })}
                onChangeText={(val) => handlePasswordChange(val)}
                InputLeftElement={<Icon2 as={<MaterialIcons name="lock-outline" />} size="xl" ml="2" color="#fff" />}
                InputRightElement={<Pressable onPress={updateSecureTextEntry}>
                  <Icon2 as={<MaterialIcons name={!data.secureTextEntry ? "visibility" : "visibility-off"} />} size={"xl"} mr="2" color="muted.400" />
                </Pressable>}
                placeholder="Password" />
            </View>
            <View style={{ flex: 2, flexDirection: "row", justifyContent: "flex-end",flexWrap:"wrap" }}>
              {customApp.loginSAML == undefined || customApp.loginSAML.url == undefined ?
                <View />
                :
                <Button key='logiSAML-btn' variant="solid" width="1/2" size={"lg"} colorScheme="primary.500"
                  onPress={web_OpenLoginSAML}>
                  {customApp.loginSAML?.title}
                </Button>
              }
              <Button variant="solid" width="1/2" colorScheme="primary.500" size={"lg"} disabled={!(data.isValidUser && data.isValidPassword)} fontWeight={"extraBlack"}
                onPress={() => authenticationStore.signIn({ username: data.username, password: data.password })}              >
                LOGIN
              </Button>

            </View>

            <View style={[{ flex: 1, flexDirection: 'column', justifyContent: "flex-end", borderWidth: 0,  flexWrap:"wrap"}]} mt="20" >
              <View style={[{ flex: 2, flexDirection: 'row', justifyContent: "space-between", borderWidth: 0, flexWrap: "wrap", alignItems: "center" }]}>
                {customApp.packagename == "com.voolks.pampaptw" ?
                  <Button onPress={() => { setHelpPTWVisible(true) }} colorScheme="primary.500">INSTRUCTIVOS</Button>
                  :
                  <></>
                }
                {customApp.hideResetPassword ?
                  <View />
                  :
                  <Button onPress={() => { setResetFormVisible(true) }} colorScheme="primary.500">RESTABLECER ACCESO</Button>
                }
                {customApp.hideRegisterButton ? <View />
                  : <Button onPress={() => { setRegisterFormVisible(true) }} colorScheme="primary.500">REGISTRARME</Button>
                }
                <Button onPress={() => { sendLogs() }} colorScheme="primary.500">ENVIAR LOGS</Button>
                <View style={[{ flex: 2, flexDirection: 'row-reverse', borderWidth: 0, flexWrap: "nowrap", }]}>
                  <Button onPress={() => { statusStore.setFlashMessage(customApp.baseUrl) }} onLongPress={() => { loadLogs() }} colorScheme="primary.500">{customApp.version}</Button>
                </View>                
              </View>

            </View>
          </View>
        </ScrollView>
      }
      <View>
        <Modal visible={registerFormVisible} onRequestClose={() => setRegisterFormVisible(false)}>
          <View backgroundColor={"primary.500"} style={{ padding: 5, flexDirection: "row", justifyContent: "space-between", maxHeight: 40 }}>
            <View width={"80%"}>
              <Text color={"primary.0"} bold={true} fontSize="lg">Registrarme</Text>
            </View>
            <View><Icon2 onPress={() => setRegisterFormVisible(false)} as={<MaterialIcons name="close" />} size={"lg"} mr="2" color="white" /></View>
          </View>
          <ScrollView>
            <RegisterFormScreen callBack={() => { setRegisterFormVisible(false) }} ></RegisterFormScreen>
          </ScrollView>
        </Modal>
        <Modal visible={resetFormVisible} onRequestClose={() => setResetFormVisible(false)}>
          <View backgroundColor={"primary.500"} style={{ padding: 5, flexDirection: "row", justifyContent: "space-between", maxHeight: 40 }}>
            <View width={"80%"}>
              <Text color={"primary.0"} bold={true} fontSize="lg">Reestablecer contraseña</Text>
            </View>
            <View><Icon2 onPress={() => setResetFormVisible(false)} as={<MaterialIcons name="close" />} size={"lg"} mr="2" color="white" /></View>
          </View>
          <ScrollView>
            <ResetForm callBack={() => { setResetFormVisible(false) }} ></ResetForm>
          </ScrollView>
        </Modal>

        <Modal visible={helpPTWVisible} onRequestClose={() => setHelpPTWVisible(false)}>
          <View backgroundColor={"primary.500"} style={{ padding: 5, flexDirection: "row", justifyContent: "space-between", maxHeight: 40 }}>
            <View width={"80%"}>
              <Text color={"primary.0"} bold={true} fontSize="lg">Instructivos</Text>
            </View>
            <View><Icon2 onPress={() => setHelpPTWVisible(false)} as={<MaterialIcons name="close" />} size={"lg"} mr="2" color="white" /></View>
          </View>
          <ScrollView>
            <View style={{ flexDirection: "column", }}>
              <View style={{ flexDirection: "row", padding: 10 }} >
                <Text>Documentación </Text>
                <Icon2
                  onPress={() => Linking.openURL("https://drive.google.com/file/d/1IqRKrADc9SsXKRSVxVsDCV0q6LTxRItQ/view?usp=drive_link")} as={<FontAwesome5 name="file-pdf" />} size={"lg"} mr="2" color={"primary.400"} />
              </View>
              <View style={{ flexDirection: "row", padding: 10 }} >
                <Text>Anexo A1 </Text>
                <Icon2 onPress={() => Linking.openURL("https://drive.google.com/file/d/1RSbKyjnx3WyJUqhKuWGv_wDR82fcZvmy/view?usp=drive_link")}
                  as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
              </View>
              <View style={{ flexDirection: "row", padding: 10 }} >
                <Text>Anexo A1 Cadena de aprobación </Text>
                <Icon2 onPress={() => Linking.openURL("https://drive.google.com/file/d/19HqCPAnnEqwwc-p6tJ85imapRl-maxNv/view?usp=drive_link")}
                  as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
              </View>
              <View style={{ flexDirection: "row", padding: 10 }} >
                <Text>Anexo A1 - Habilitante </Text>
                <Icon2 onPress={() => Linking.openURL("https://drive.google.com/file/d/14eTY5WTNr1N3QAjL5GASaK5uJVSpnmwO/view?usp=drive_link")}
                  as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
              </View>
              <View style={{ flexDirection: "row", padding: 10 }} >
                <Text>Anexo A1 - Cierre del Trabajo </Text>
                <Icon2 onPress={() => Linking.openURL("https://drive.google.com/file/d/14eTY5WTNr1N3QAjL5GASaK5uJVSpnmwO/view?usp=drive_link")}
                  as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
              </View>
              <View style={{ flexDirection: "row", padding: 10 }} >
                <Text>Anexo A2 </Text>
                <Icon2 onPress={() => Linking.openURL("https://drive.google.com/file/d/14PTl4X_6SeYY35q8z9eNX94D02IYwxCN/view?usp=drive_link")}
                  as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
              </View>
              <View style={{ flexDirection: "row", padding: 10 }} >
                <Text>Anexo A3 </Text>
                <Icon2 onPress={() => Linking.openURL("https://drive.google.com/file/d/1IZV7c2Mg0l_TtuKfciHpGaScE35XDiHa/view?usp=drive_link")}
                  as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
              </View>
              <View style={{ flexDirection: "row", padding: 10 }} >
                <Text>Anexo A4 </Text>
                <Icon2 onPress={() => Linking.openURL("https://drive.google.com/file/d/1tNYLKltjtscGLj2eehmb4IVqmjzQA_zF/view?usp=drive_link")}
                  as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
              </View>
            </View>
          </ScrollView>
        </Modal>
        <Modal visible={webViewVisible} onRequestClose={() => setWebViewVisible(false)}>
          <View backgroundColor={"primary.500"} style={{ padding: 5, flexDirection: "row", justifyContent: "space-between", maxHeight: 40 }}>
            <View width={"80%"}>
              <Text color={"primary.0"} bold={true} fontSize="lg">{customApp.loginSAML?.title}</Text>
            </View>
            <View><Icon2 onPress={() => setWebViewVisible(false)} as={<MaterialIcons name="close" />} size={"lg"} mr="2" color="white" /></View>
          </View>
          {Platform.OS != "web" ?
            <WebView
              source={{ html }}
              onMessage={WebView_onMessage}
            />
            :
            <WebViewForWeb
              startInLoadingState={true}
              source={{ html: `<h1>Loading Login Provider...</h1><script>document.location.href='${customApp.loginSAML?.url}';</script>` }}
              style={{ marginTop: 20, minHeight: 600 }}
              onMessage={WebView_onMessage}
              hideStatusBar={false}
              hideAddressBar={false}
              hideActivityIndicator={false}
            />
          }
        </Modal>


      </View>

    </View>
  )
})


const $container: ViewStyle = {
  flex: 1, width: "100%",
  minHeight: 520,
  flexDirection: "column",
  justifyContent: "flex-end",

}

const $topContainer: ViewStyle = {
  flexShrink: 1,
  flexGrow: 1,
  flexBasis: "25%",
  justifyContent: "center",
}
const $middleContainer: ViewStyle = {
  flexShrink: 1,
  flexGrow: 1,
  flexBasis: "25%",
  justifyContent: "center",
}


const $welcomeLogo: ImageStyle = {
  height: "60%",
  width: "80%",
  maxWidth: 450,
  resizeMode: "center", padding: 0,
  //justifyContent: "space-around",
  alignSelf: "center",

}


