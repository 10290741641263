import * as React from "react";
import {
    StyleSheet,
    View,
    Text

} from "react-native";
import QRCode from 'react-native-qrcode-svg';
import { RNPicker } from "../components/base/RNPicker";
import { useStores } from "../models";
import { getItemTitle } from "../utils/itemCommonFunctions";
import { HStack, VStack, Button, ButtonText, ScrollView } from "@gluestack-ui/themed";
import * as Print from 'expo-print';
import { shareAsync } from 'expo-sharing';
import { printToPrinter, SelectedPrinter, BOLD_ON, BOLD_OFF, CENTER, LEFT, HR2, FONT_M, FONT_S, getListDevices } from '../utils/printer';
import { customApp } from "../config";

export default function PrinterDialog(props) {
    const { authenticationStore, itemsStore } = useStores();
    const [selectedPrinterType, setSelectedPrinterType] = React.useState<string>("ble");
    const [devices, setDevices] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedPrinter, setSelectedPrinter] = React.useState<SelectedPrinter>(
        {}
    );
    let QrRef = React.useRef<any>(null);
    const form = props.form;
    const item = itemsStore.itemActive;
    const user = authenticationStore.userInfo.user;
    let document: string = `${CENTER}${BOLD_ON}${FONT_S}Comprobante Movil\n${FONT_M}${form.name}${BOLD_OFF}${FONT_S}\n${CENTER}${HR2}\n${LEFT}${getItemTitle(form, item)}\n${CENTER}${HR2}\n${LEFT}Cierre: ${new Date(item.closedAt).getYYYYMMddHHmmss()}\n ${user.email}\nRND_ID : ${item._rnd}\nID: ${item.id || 'Pendiente de Subir'}\n`;

    let document2: string = `Comprobante Movil\n${form.name}\n${HR2}\n${getItemTitle(form, item)}\n${HR2}\nCierre: ${new Date(item.closedAt).getYYYYMMddHHmmss()}\n ${user.email}\nRND_ID : ${item._rnd}\nID: ${item.id || 'Pendiente de Subir'}\n`;

    let html: string = `<center><bold><small>Comprobante Movil</small><br/>${form.name}</b><br/></center><hr/><br><small>${getItemTitle(form, item)}<hr/>Cierre: ${new Date(item.closedAt).getYYYYMMddHHmmss()}<br/> ${user.email}<br/>RND_ID : ${item._rnd}<br>`;

    let qrCode_value = `URL:${customApp.baseUrl}api/departments/${item.deptId.toString()}/forms/${item.formId.toString()}/html_rnd/${item._rnd}`
    console.log(qrCode_value)
    React.useEffect(() => {

        getListDevices(selectedPrinterType).then((results: any[]) => {
            results = results || [];
            results.push({ device_name: "Compartir como PDF", inner_mac_address: "FILE", printerType: "PDF" })
            setDevices(results.map((item: any) => ({ ...item, printerType: (item.printerType || "ble") })));
            if (results.length > 0)
                setSelectedPrinter({ ...results[0], printerType: (results[0].printerType || "ble") });
        });
    }, [selectedPrinterType]);


    const print = async (receipt: boolean) => {

        (QrRef as any).toDataURL(async (dataUrl: string) => {
            if (selectedPrinter.printerType == "PDF") {
                let doc = (`<center><img src="data:image/png;base64,${dataUrl}" style="width: 100px;" /></center>`)
                if (receipt)
                    doc = html.concat(doc);
                const { uri } = await Print.printToFileAsync({ html: `<style>@page {width:58mm;}</style>${doc}`, width: 200 });
                console.log('File has been saved to:', uri);
                await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
            } else {
                printToPrinter(selectedPrinter, receipt, dataUrl, document);
            }
        });
    };

    const printQRCode = () => print(false);
    const printReceipt = () => print(true);





    return (
        <VStack style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>

            <VStack alignItems="center" bgColor="$white">

                <ScrollView horizontal={true} bgColor={"white"}>
                    <Text>{document2}</Text>

                </ScrollView>
                <QRCode
                    logo={require("../../assets/images/fieldlogo_circle.png")}
                    logoSize={20}
                    value={qrCode_value}
                    logoBackgroundColor='transparent'
                    getRef={(el: any) => (QrRef = el)}
                />
                <View >
                    <HStack marginTop="$5" bgColor="$light100" alignContent={"center"} alignItems={"center"} borderColor="$light300" borderWidth={1}>
                        <Text>Impresora</Text>
                        <RNPicker testID="rnpicker-MultipleEl"
                            width="80%"
                            data={(devices || [])?.map((item, idx) => ({ id: idx.toString(), name: `${item.device_name}(${item.inner_mac_address})` }))}
                            defaultValue={true}
                            selectedText={selectedPrinter ? selectedPrinter.device_name : undefined}
                            selectedValue={(index: number, item: SelectedPrinter) => setSelectedPrinter(devices[index])}
                        />
                    </HStack>
                    <HStack bgColor="$primary400" justifyContent="center">
                        <Text style={{color:"#fff"}}>IMPRIMIR</Text>
                    </HStack>
                    <HStack marginTop="$0">
                        <Button width={"$1/2"} height="$15" borderRadius={0}
                            disabled={!selectedPrinter?.device_name}
                            onPress={printQRCode}
                        ><ButtonText>Código Qr</ButtonText>
                        </Button>
                        <Button width={"$1/2"} height="$15" borderRadius={0}
                            disabled={!selectedPrinter?.device_name}
                            onPress={printReceipt}
                        >
                            <ButtonText>Comprobante</ButtonText>
                        </Button>
                    </HStack>
                </View>
            </VStack>


        </VStack>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        padding: 16,
    },
    section: {
        flex: 1,
    },
    rowDirection: {
        flexDirection: "row",
    },
});