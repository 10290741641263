import React, { FunctionComponent } from 'react';
import { Linking, Image as ImageRN, Pressable, View } from 'react-native';
import { IElementBaseProps, ModalFull, useInitializeElement } from '..';
import { MaterialIcons, } from "@expo/vector-icons";
import ImageViewer from 'react-native-image-zoom-viewer';
import { useStores } from '../../models';

import { Icon, Box, Button, ButtonIcon, ScrollView, HStack, VStack, Divider, Image, Text } from "@gluestack-ui/themed"
import Spacer from '../base/Spacer';



const getAsShowCase = (element) => {
    const { statusStore } = useStores();
    const [fullscreenImage, setFullScreenImage] = React.useState<boolean>(false);
    const [sizeImg, setSizeImg] = React.useState<{ height: number, width: number }>({ height: 200, width: statusStore.windowDimensions.width - 20 });
    const [rendered, setRender] = React.useState(<VStack><HStack minHeight={45}></HStack></VStack>);
    const image: string = element.templateData?.image;
    const logo: string = element.templateData?.logo;
    const body: string = element.templateData?.body;
    const link: string = element.templateData?.href;


    const renderMe = () => {
        return <VStack marginVertical={"$1.5"} marginHorizontal="$1">
            <HStack minHeight={45}
                bgColor={element.templateData?.backgroundColor || "#fff"}
                alignItems="center"
                padding={"$1"}
            >
                {logo ? <Image borderRadius="$xl" maxWidth={50} maxHeight={50} resizeMode='contain' source={{ uri: logo }} alt={element.name || ''} /> : <></>}
                <VStack marginLeft={4} flexShrink={1} >
                    <Text color={element.templateData.textColor || "#7a7373"} fontSize="$md" fontWeight="bold">{element.name || ''}</Text>
                    {element.templateData?.noteTitle? <Text color={element.templateData.textColor || "#7a7373"} fontSize="$xs">{element.templateData?.noteTitle || ''}</Text>:<></>}
                </VStack>
            </HStack>
            {image ?
                <HStack py={1} minHeight={45}
                    bgColor={element.templateData?.backgroundColor2 || "#fff"}
                    fontWeight={"bold"}
                    fontSize={"lg"} alignItems={"center"} justifyContent={"center"}
                    padding={3}
                >
                    <Pressable onPress={() => setFullScreenImage(true)}>
                        <Image style={{ height: sizeImg.height, width: sizeImg.width, maxHeight: statusStore.windowDimensions.height / 2 }}
                            resizeMode={"contain"} source={{ uri: image }} alt={element.name || ''} />
                    </Pressable>
                </HStack>
                : <></>}
            {body ?
                <HStack py={1} bgColor={element.templateData?.backgroundColor2 || "#fff"}
                    alignItems={"center"} justifyContent={"center"}
                    padding={3}
                ><Text color={element.templateData.textColor2 || "#7a7373"} fontSize="$sm" textAlign="justify">{body}</Text>
                </HStack>
                : <></>}
            {link ?
                <Pressable onPress={() => Linking.openURL(element.templateData.href)}>
                    <HStack space="md" marginBottom={5} style={{ paddingLeft: 10, paddingRight: 15 }} alignItems=''>
                        <View width="60%" />
                        <Text color={"$closed400"} fontWeight='$extrabold' fontSize="$sm">
                            ABRIR
                        </Text><Icon name="launch" as={MaterialIcons} size={"md"} color={"$closed400"} />
                    </HStack>
                </Pressable>
                : <></>}
        </VStack>
    }
    React.useEffect(() => {
        if (image != undefined)
            ImageRN.getSize(image, (w, h) => {
                let widthImg = sizeImg.width;
                if (w < widthImg) {
                    setSizeImg({ height: h, width: w })
                } else {
                    setSizeImg({ height: widthImg / w * h, width: widthImg })
                }

            })
        setTimeout(() => setRender(renderMe()), 10);
    }, [])

    return <Box p={2}>
        {rendered}
        {fullscreenImage && <ModalFull
            visible={fullscreenImage}
            title={element.name || element.label}
            closeButton_onPress={() => { setFullScreenImage(false) }}
        ><View width="100%" height="100%" bgColor={"white"} alignSelf={"center"} borderWidth={1}>
                <ImageViewer enableImageZoom={true} saveToLocalByLongPress={false}
                    imageUrls={[{ url: image }]}
                    renderIndicator={() => <Text />}
                    menuContext={{ saveToLocal: 'Guardar', cancel: 'Cancelar' }}
                />
            </View>
        </ModalFull>}

    </Box>
}

const getSimpleTitle = (element) => {
    return <Box>
        <HStack minHeight={45} paddingHorizontal={"$1"}
            bgColor={element.templateData?.backgroundColor || "#fff"}
            color={element.templateData.textColor || "#000"}
            alignContent={"center"}
            padding={"$2"}
        ><Text color={element.templateData.textColor || "#000"}
            fontWeight={"bold"}
            fontSize="$md">{element.name || element.label}</Text>
            {element.templateData?.href ?<>
                <Spacer/>                    
                <Pressable onPress={() => Linking.openURL(element.templateData.href)}>
                    <HStack space="md" marginBottom={5} style={{ paddingLeft: 10, paddingRight: 15 }} >
                        <Text color={"$closed400"} fontWeight='$extrabold' fontSize="$sm">                            
                        </Text><Icon name="launch" as={MaterialIcons} size={"md"} color={"$closed400"} />
                    </HStack>                    
                </Pressable>
                </>
                : <></>
            }
        </HStack>
    </Box>
}

export const TitleElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const { } = useInitializeElement(props);
    const element = props.element;
    if (element.templateData?.hidden)
        return <></>
    return element.templateData?.asShowCase ? getAsShowCase(element) : getSimpleTitle(element);
};

